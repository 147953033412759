import React, { useState, useLayoutEffect } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./homeProducts.module.scss";
import cx from "classnames";
import Arrow from "../icons/arrow";
import { PageLink } from "../components/link";

export const HomeProducts = ({ data }) => {
  // const initialValue = []

  // const populateState = () => {
  //     data.products.map((product) => {
  //         let obj = {
  //           product: product,
  //           hovered: false,
  //         }
  //         initialValue.push(obj)
  //       })
  // }
  //   populateState()

  const [productHovered, setProductHovered] = useState(-1);
  const [productCentered, setProductCentered] = useState(false);
  const [largePadding, setLargePadding] = useState(false);

  const hoverProduct = i => {
    setProductHovered(i);
  };

  const unHoverProduct = () => {
    setProductHovered(-1);
  };
  //   const hoverProduct = (e) => {
  //     e.preventDefault()

  //     let productIndex = e.target.dataset.index

  //     if (productList[productIndex] !== undefined) {
  //         setProductActive(!productList[productIndex].hovered)
  //     }

  //   }

  const handleScroll = (windowHeight, products) => {
    const position = window.pageYOffset;

    products.forEach((product, index) => {
      let productTop = windowHeight * 0.2;
      let productBottom = windowHeight * 0.7;
      let productHeight = product.offsetHeight;
      let currentDistanceFromTop = product.getBoundingClientRect().top;

      // if closer to top than distance
      if (windowHeight >= productHeight + currentDistanceFromTop * 2) {
        setProductHovered(index);
      } else {
        if (index === 0) {
          setProductHovered(-1);
        }
      }
    });
  };

  useLayoutEffect(() => {
    let products = document.querySelectorAll(".productItem");
    let windowHeight = window.innerHeight;

    const callHandleScroll = () => {
      return handleScroll(windowHeight, products);
    };

    const pathArray = window.location.pathname.split("/");
    const handle = pathArray[1];
    if (handle === "shop") {
      setLargePadding(true);
    }

    window.addEventListener("scroll", callHandleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", callHandleScroll);
    };
  }, [productHovered]);

  return (
    <section
      className={(largePadding === true ? "pb-40" : "pb-8") + " container"}
    >
      <div className="grid grid-cols-1 md:grid-cols-3 w-full relative">
        {data.products.map((product, i) => (
          <PageLink
            link={"/" + product.link[0].link.page.content.slug.current}
            onMouseEnter={() => {
              setProductHovered(i);
            }}
            onMouseLeave={unHoverProduct}
            data-index={i}
            key={i}
            className="col-span-1 flex flex-col items-start border-r border-dashed border-gold relative"
          >
            <div className="productItem pointer-events-none flex justify-center w-full border-b border-t border-dashed border-gold">
              <SanityImage
                className={cx(
                  productHovered === i ? "hidden" : "flex",
                  styles.home__productsImage,
                  "pointer-events-none"
                )}
                alt={product.alt}
                asset={product.image.asset}
              />

              <SanityImage
                className={cx(
                  productHovered === i ? "flex" : "hidden",
                  styles.home__productsImage,
                  "pointer-events-none"
                )}
                alt={product.alt}
                asset={product.hoverGif.asset}
              />
            </div>
            <div className="absolute top-0 left-0 pt-8 md:pt-2 md:relative flex flex-col">
              <div
                className={cx(
                  productHovered === i
                    ? "font-historicalItalic"
                    : "font-historical",
                  "pl-4 text-lg"
                )}
              >
                {product.title}
                <span>
                  <Arrow
                    className={cx(
                      productHovered === i
                        ? "hidden md:inline-block"
                        : "hidden",
                      "ml-2.5 z-10"
                    )}
                  />
                </span>
              </div>
              <div className="font-serif lowercase pl-4 text-xs text-gold">
                {product.price}
              </div>
            </div>
          </PageLink>
        ))}
        <div className="absolute w-px top-0 right-0 h-full bg-offWhite z-10" />
      </div>
    </section>
  );
};

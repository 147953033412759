import React, { useState } from "react";
import { graphql } from "gatsby";

import { Seo } from "../components/seo";
import { renderSections } from "../lib/renderSections";

import * as pdp from "../sections/pdp.module.scss";
import cx from "classnames";

import pdpProduct1 from "../../static/images/pdp--product-1.jpg";
import pdpProduct2 from "../../static/images/pdp--product-2.jpg";
import pdpProduct3 from "../../static/images/pdp--product-3.jpg";

import { PageLink } from "../components/link";
import SanityImage from "gatsby-plugin-sanity-image";
import { HomeProducts } from "../sections/homeProducts";
import badgeBlanco from "../../static/images/celaya-labelbadge-05.png";
import badgeReposado from "../../static/images/celaya-labelbadge-06.png";
import badgeAnejo from "../../static/images/celaya-labelbadge-07.png";
import * as styles from "./plpProduct.module.scss";

import Star from "../icons/star";
import StarEmpty from "../icons/starEmpty";
import Flickity from "react-flickity-component";
import pdpMobile from "../../static/images/pdp--mobile.jpg";
import { PDPStory } from "./pdpStory";
import PortableText from "../components/portableText";
import { PDPHeaderSingle } from "./pdpHeaderSingle";
import { PDPHeaderBundle } from "./pdpHeaderBundle";
import { PDPHeaderCase } from "./pdpHeaderCase";

export const PDP = ({ data }) => {
  const flickityOptionsReview = {
    pageDots: true,
    prevNextButtons: false,
    accessibility: true,
    draggable: true,
    wrapAround: true
  };

  return (
    <>
      {data.type === "bundle" ? (
        <PDPHeaderBundle data={data} />
      ) : data.type === "case" ? (
        <PDPHeaderCase data={data} />
      ) : (
        <PDPHeaderSingle data={data} />
      )}
      <PDPStory data={data} />

      <div
        className={cx(
          pdp.pdp__reviews_bg,
          "py-16 md:py-20 mb-32 pdp__reviews hidden"
        )}
      >
        <div className="container">
          <div className="text-sm text-gold mb-4">Reviews</div>
          <div className="font-serif lowercase leading-none text-xl mb-10">
            What Our Customers Say
          </div>

          <div className="md:hidden">
            <Flickity
              className="border-t border-dashed border-gold"
              options={flickityOptionsReview}
              reloadOnUpdate
            >
              <div className="pt-6 border-r border-dashed border-gold cell--review">
                <div className="flex justify-between items-center px-4">
                  <div className="flex">
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="17"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.4 2.156 9.72 6.22l.113.346H14.47l-3.458 2.512-.293.213.112.346 1.32 4.064-3.457-2.512-.294-.213-.294.213-3.457 2.512 1.32-4.064.113-.346-.294-.213L2.33 6.566H6.967l.113-.346L8.4 2.156Z"
                        stroke="#404040"
                      />
                    </svg>
                  </div>
                  <div className="font-serif">27.07.2021</div>
                </div>
                <div className="py-4 px-4 font-historical text-sm md:text-lg leading-tight">
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nulla posuere hendrerit risus sed.”
                </div>
                <div className="py-2 px-4 border-t border-dashed border-gold font-historical text-sm md:text-lg">
                  –Jack
                </div>
              </div>
              <div className="pt-6 border-r border-dashed border-gold cell--review">
                <div className="flex justify-between items-center px-4">
                  <div className="flex">
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="17"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.4 2.156 9.72 6.22l.113.346H14.47l-3.458 2.512-.293.213.112.346 1.32 4.064-3.457-2.512-.294-.213-.294.213-3.457 2.512 1.32-4.064.113-.346-.294-.213L2.33 6.566H6.967l.113-.346L8.4 2.156Z"
                        stroke="#404040"
                      />
                    </svg>
                  </div>
                  <div className="font-serif">27.07.2021</div>
                </div>
                <div className="py-4 px-4 font-historical text-sm md:text-lg leading-tight">
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nulla posuere hendrerit risus sed.”
                </div>
                <div className="py-2 px-4 border-t border-dashed border-gold font-historical text-sm md:text-lg">
                  –Jack
                </div>
              </div>
              <div className="pt-6 border-r border-dashed border-gold cell--review">
                <div className="flex justify-between items-center px-4">
                  <div className="flex">
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                        fill="#000"
                      />
                    </svg>
                    <svg
                      width="17"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.4 2.156 9.72 6.22l.113.346H14.47l-3.458 2.512-.293.213.112.346 1.32 4.064-3.457-2.512-.294-.213-.294.213-3.457 2.512 1.32-4.064.113-.346-.294-.213L2.33 6.566H6.967l.113-.346L8.4 2.156Z"
                        stroke="#404040"
                      />
                    </svg>
                  </div>
                  <div className="font-serif">27.07.2021</div>
                </div>
                <div className="py-4 px-4 font-historical text-sm md:text-lg leading-tight">
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nulla posuere hendrerit risus sed.”
                </div>
                <div className="py-2 px-4 border-t border-dashed border-gold font-historical text-sm md:text-lg">
                  –Jack
                </div>
              </div>
            </Flickity>
          </div>
          <div className="hidden md:grid grid-cols-3 border-t border-dashed border-gold">
            <div className="pt-10 border-r border-dashed border-gold">
              <div className="flex justify-between items-center px-4">
                <div className="flex">
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="17"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.4 2.156 9.72 6.22l.113.346H14.47l-3.458 2.512-.293.213.112.346 1.32 4.064-3.457-2.512-.294-.213-.294.213-3.457 2.512 1.32-4.064.113-.346-.294-.213L2.33 6.566H6.967l.113-.346L8.4 2.156Z"
                      stroke="#404040"
                    />
                  </svg>
                </div>
                <div className="font-serif">27.07.2021</div>
              </div>
              <div className="py-4 px-4 font-historical text-lg leading-tight">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                posuere hendrerit risus sed.”
              </div>
              <div className="py-2 px-4 border-t border-dashed border-gold font-historical text-lg">
                –Jack
              </div>
            </div>

            <div className="pt-10 border-r border-dashed border-gold">
              <div className="flex justify-between items-center px-4">
                <div className="flex">
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                </div>
                <div className="font-serif">27.07.2021</div>
              </div>
              <div className="py-4 px-4 font-historical text-lg leading-tight">
                “Nulla aliquam faucibus dui. Sed dignissim urna eget arcu
                pellentesque, vitae tristique risus iaculis.”
              </div>
              <div className="py-2 px-4 border-t border-dashed border-gold font-historical text-lg">
                –Kate
              </div>
            </div>

            <div className="pt-10">
              <div className="flex justify-between items-center px-4">
                <div className="flex">
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
                      fill="#000"
                    />
                  </svg>
                </div>
                <div className="font-serif">27.07.2021</div>
              </div>
              <div className="py-4 px-4 font-historical text-lg leading-tight">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                posuere hendrerit risus sed.”
              </div>
              <div className="py-2 px-4 border-t border-dashed border-gold font-historical text-lg">
                –John
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="font-serif lowercase leading-none text-xl mb-10 text-center">
        Complete the Setting
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "../components/portableText";
import * as styles from "./homeHistory.module.scss";
import cx from "classnames";
import { roundToNearestMinutes } from "date-fns";

export const HomeHistory = ({ data }) => {
  const [discovery1Active, setDiscovery1Active] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(-1);

  const toggleDiscovery1Hovered = () => {
    setDiscovery1Active(!discovery1Active);
  };

  useEffect(() => {
    const spanTriggers = document.querySelectorAll("[data-trigger]");

    spanTriggers.forEach((trigger, index) => {
      let triggerIndex = index;
      let triggerTexture = trigger.querySelector("div");
      let textArr = data.text;
      let triggerArr = [];
      textArr.forEach(block => {
        if (block.textureRotation) {
          triggerArr.push(block);
        }
      });

      triggerArr.forEach((block, index) => {
        let textureIndex = index;

        if (textureIndex === triggerIndex) {
          triggerTexture.style.transform = `rotate(${
            block.textureRotation
          }deg)`;
        }
      });

      trigger.addEventListener("mouseenter", () => {
        trigger.style.color = "#C8A677";
        // triggerTexture.style.opacity = '0.66'
        setHoveredItem(trigger.dataset.trigger);
      });

      trigger.addEventListener("mouseleave", () => {
        trigger.style.color = "#404040";
        // triggerTexture.style.opacity = '0.33'
        setHoveredItem(-1);
      });
    });

    let triggered = 1;

    document.addEventListener("scroll", function() {

      const spanTrigger = document.querySelector("[data-trigger='5']");

      const height = window.innerHeight;
      const windowScroll = window.scrollY;

      const element = document.querySelector("#history");

      /* Because I don't understand why this code is still firing on pages
      that don't include the homeHistory section, I am just checking if
      the #history element exists, and otherwise we stop it here.
      Hopefully we can clean it up later.  */

      if ( element !== null ) {

        const bodyRect = document.body.getBoundingClientRect(),
          elemRect = element.getBoundingClientRect(),
          offset = elemRect.top - bodyRect.top;

        const check = Math.abs(height - offset);

        if (check + 200 < windowScroll && triggered === 1) {
          spanTrigger.style.color = "#C8A677";
          setHoveredItem(5);
          triggered = 0;
        } else if (check + 200 + height / 2 < windowScroll) {
          spanTrigger.style.color = "#404040";
          setHoveredItem(-1);
          //triggered = 1;
        }

      }

    });
  }, []);

  return (
    <section
      className={cx(styles.home_historyContainer, "relative flex flex-col")}
    >
      {data.discoveryItems.map((item, key) => {
        return (
          <div
            key={key}
            style={
              hoveredItem == key + 1
                ? item.alignment === "left"
                  ? {
                      top: `${item.imageYOffset}%`,
                      left: `${item.imageXOffset}%`,
                      opacity: "1"
                    }
                  : {
                      top: `${item.imageYOffset}%`,
                      left: `${item.imageXOffset}%`,
                      opacity: "1"
                    }
                : {
                    top: `${item.imageYOffset}%`,
                    left: `${item.imageXOffset}%`
                  }
            }
            className={cx(
              "hidden md:flex",
              item.alignment === "left"
                ? styles.home_historyImageLeft
                : styles.home_historyImageRight
            )}
          >
            <div
              style={
                item.tiltDirection === "left"
                  ? { transform: `rotate(-${item.tilt}deg)` }
                  : { transform: `rotate(${item.tilt}deg)` }
              }
            >
              <SanityImage
                alt={item.image.alt}
                asset={item.image.asset}
                width={key === 0 ? 206 : 308}
                height={key === 0 ? 380 : key === 4 ? 250 : 206}
              />
            </div>
          </div>
        );
      })}

      {/* className={cx(styles.home_historyText, "font-historical text-center text-2xl w-full md:w-1/2 z-10")} */}
      <div
        id="history"
        className="w-9/12 text-center text-darkGray z-1 hidden md:flex"
      >
        <PortableText blocks={data.text} />
      </div>

      <div className="md:hidden flex flex-col pt-16">
        {data.mobileText.map((block, key) => {
          return (
            <div
              key={key}
              className="w-full pb-72 font-historical text-darkGray text-center text-base px-8"
            >
              {block}
            </div>
          );
        })}
      </div>

      {data.mobileImages.map((item, key) => {
        return (
          <div
            key={key}
            style={
              item.alignment === "left"
                ? { top: `${item.imageYOffset}%`, left: "0" }
                : { top: `${item.imageYOffset}%`, right: "-5%" }
            }
            className={cx(
              "flex md:hidden",
              item.alignment === "left"
                ? styles.home_historyImageLeftMobile
                : styles.home_historyImageRightMobile
            )}
          >
            <div
              style={
                item.tiltDirection === "left"
                  ? { transform: `rotate(-${item.tilt}deg)` }
                  : { transform: `rotate(${item.tilt}deg)` }
              }
            >
              <SanityImage
                alt={item.image.alt}
                asset={item.image.asset}
                width={308}
                height={206}
              />
            </div>
          </div>
        );
      })}
    </section>
  );
};

import cx from "classnames";

import SanityImage from "gatsby-plugin-sanity-image";
import badgeBlanco from "../../static/images/celaya-labelbadge-05.png";
import badgeReposado from "../../static/images/celaya-labelbadge-06.png";

import Star from "../icons/star";
import StarEmpty from "../icons/starEmpty";
import Flickity from "react-flickity-component";
import * as pdp from "./pdp.module.scss";
import React, { useState, useEffect } from "react";
import ThemeContext from "../context/ThemeContext";
import { StoreContext } from "../context/store-context";
import {AddToCart} from "../components/addToCart";

export const PDPHeaderCase = ({ data }) => {
  const [type, setType] = useState("mixed");
  const [title, setTitle] = useState("Mixed");
  const [price, setPrice] = useState("346");
  const [count, setCount] = useState(1);

  const increaseCount = () => {
    setCount(count + 1);
  };

  const decreaseCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const flickityOptions = {
    pageDots: true,
    prevNextButtons: false,
    accessibility: true,
    draggable: true,
    wrapAround: true,
    groupCells: 1
  };

  const optionChange = (type, title, price) => {
    setType(type);
    setTitle(title);
    setPrice(price);
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = new URLSearchParams(window.location.search);
      const urlType = url.get("type");
      if (urlType) {
        if (urlType === "blanco") {
          setType("blanco");
          setTitle("Blanco");
          setPrice("254");
        } else if (urlType === "reposado") {
          setType("reposado");
          setTitle("Reposado");
          setPrice("290");
        }
      }
    }
  }, []);

  return (
    <ThemeContext.Consumer>
      {theme => (
        <>
          <div className="container pt-6 md:pt-20 mt-20 mb-12 md:mb-20">
            <div className="md:hidden">
              <Flickity
                className={cx(
                  pdp.carousel,
                  "carousel mb-50 md:mb-70 lg:mb-90 w-full"
                )}
                options={flickityOptions}
                reloadOnUpdate
              >
                {data.images.map((image, i) => (
                  <div data-index={i} key={i} className={cx(pdp.carousel_cell)}>
                    <SanityImage
                      _key={i}
                      alt={image.alt}
                      class={cx(type === image.type ? "block" : "hidden")}
                      asset={image.image.asset}
                    />
                  </div>
                ))}
              </Flickity>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="hidden md:block">
                {data.images.map((image, i) => (
                  <SanityImage
                    data-index={i}
                    key={i}
                    _key={i}
                    className={cx(
                      type === image.type ? "block" : "hidden",
                      "mb-3"
                    )}
                    alt={image.alt}
                    asset={image.image.asset}
                  />
                ))}
              </div>

              <div className="md:px-16">
                <div className="md:px-4 bg-offWhite sticky top-40">
                  {type === "blanco" ? (
                    <img
                      src={badgeBlanco}
                      alt="100 Percent Agave - Blanco"
                      className="absolute top-0 md:right-2 left-2 md:left-auto w-14 md:w-20"
                    />
                  ) : (
                    ""
                  )}
                  {type === "reposado" ? (
                    <img
                      src={badgeReposado}
                      alt="Barrel Aged Reposado"
                      className="absolute top-0 md:top-20 md:right-2 left-2 md:left-auto w-14 md:w-20"
                    />
                  ) : (
                    ""
                  )}
                  <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                    <div className="flex justify-center items-center w-full h-full border border-gold py-4 px-8">
                      <div className="pt-6 pb-6 w-full text-center">
                        <p className="font-historical text-lg md:text-2xl">
                          The Case Deal: {title}
                        </p>
                        <p className="font-serif text-gold text-md">${price}</p>
                        <div className="flex justify-center items-center mt-4 hidden">
                          <Star height="12" width="12" />
                          <Star height="12" width="12" />
                          <Star height="12" width="12" />
                          <Star height="12" width="12" />
                          <StarEmpty height="12" width="12" />
                          <div
                            className={cx(pdp.pdp__stat_heading, "ml-2 mb-1")}
                          >
                            101 Reviews
                          </div>
                        </div>
                        <div className="mb-4 md:mt-6 pb-6 font-serif text-xs border-b border-gold border-dashed lowercase">
                          <div
                            className={cx(
                              type === "mixed" ? "hidden md:block" : "hidden"
                            )}
                          >
                            There’s nothing more hospitable than having a
                            well-stocked bar. With all three flavors in hand you
                            can make sure each friend gets the expression they
                            want.
                          </div>
                          <div
                            className={cx(
                              type === "blanco" ? "hidden md:block" : "hidden"
                            )}
                          >
                            our blanco brings the vibrant flavours of mexico’s
                            blue agave to the front of your mouth. as you sip
                            and enjoy, the subtle and rounded, herbal aromas
                            give way to bright citrus notes. clean & fresh this
                            tequila is perfect for sipping slowly or mixing for
                            cocktails.
                          </div>
                          <div
                            className={cx(
                              type === "reposado" ? "hidden md:block" : "hidden"
                            )}
                          >
                            our reposado is a complex, full bodied tequila with
                            soft and sweet notes of dried fruit. made from 100%
                            agave, this tequila is rested for 3 months in
                            retired bourbon casks, giving it a warm, spicy and
                            oak finish. golden caramel in colour, this reposado
                            is easy to sip with friends or blend as a cocktail.
                          </div>
                        </div>
                        <div className="mb-6 pb-4 border-b border-gold border-dashed lowercase text-left">
                          <label className={cx(pdp.formControl)}>
                            <input
                              type="radio"
                              name="product"
                              value="mixed"
                              checked={type === "mixed"}
                              data-shopify="41932991529150"
                              onChange={() => {
                                optionChange("mixed", "Mixed", "346");
                              }}
                            />
                            <span className="text-xl leading-none mb-4">
                              6×Mixed <sup>$346</sup>
                            </span>
                          </label>
                          <label className={cx(pdp.formControl)}>
                            <input
                              type="radio"
                              name="product"
                              value="blanco"
                              checked={type === "blanco"}
                              data-shopify="41968095101118"
                              onChange={() => {
                                optionChange("blanco", "Blanco", "254");
                              }}
                            />
                            <span className="text-xl leading-none mb-4">
                              6×Blanco <sup>$254</sup>
                            </span>
                          </label>
                          <label className={cx(pdp.formControl)}>
                            <input
                              type="radio"
                              name="product"
                              value="reposado"
                              checked={type === "reposado"}
                              data-shopify="41968099197118"
                              onChange={() => {
                                optionChange("reposado", "Reposado", "290");
                              }}
                            />
                            <span className="text-xl leading-none mb-4">
                              6×Reposado <sup>$290</sup>
                            </span>
                          </label>
                        </div>
                        <div className="mb-6 flex justify-between items-center">
                          <div className="flex">
                            <div
                              onClick={decreaseCount}
                              className={cx(
                                pdp.btn_qty,
                                "mr-2 font-serif text-gold rounded-sm border-gold border-2 hover:opacity-80 select-none"
                              )}
                            >
                              -
                            </div>
                            <div
                              onClick={increaseCount}
                              className={cx(
                                pdp.btn_qty,
                                "font-serif text-gold rounded-sm border-gold border-2 hover:opacity-80 select-none"
                              )}
                            >
                              +
                            </div>
                          </div>
                          <div
                            id="count"
                            data-count={count}
                            className="text-xl leading-none mb-4"
                          >
                            QTY: {count}
                          </div>
                        </div>

                        <AddToCart theme={theme} count={count} />

                        <div className="text-left font-serif text-3xs text-gold mt-8">
                          <span className="uppercase">We Ship to:</span>{" "}
                          <span className="lowercase">
                            AK, AZ, CA, CO, CT, DE, FL, GA, ID, IL, IN, KS, KY, LA, MD, MO, NE, NV, NH, NJ, NM, NY, NC, ND, OH, OR, PA, RI, TX, VA, WA, WI
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:hidden text-center">
            <div className="px-10 pb-12 font-serif text-xs border-b border-gold border-dashed lowercase">
              <div className={cx(type === "mixed" ? "block" : "hidden")}>
                There’s nothing more hospitable than having a well-stocked bar.
                With all three flavors in hand you can make sure each friend
                gets the expression they want.
              </div>
              <div className={cx(type === "blanco" ? "block" : "hidden")}>
                our blanco brings the vibrant flavours of mexico’s blue agave to
                the front of your mouth. as you sip and enjoy, the subtle and
                rounded, herbal aromas give way to bright citrus notes. clean &
                fresh this tequila is perfect for sipping slowly or mixing for
                cocktails.
              </div>
              <div className={cx(type === "reposado" ? "block" : "hidden")}>
                our reposado is a complex, full bodied tequila with soft and
                sweet notes of dried fruit. made from 100% agave, this tequila
                is rested for 3 months in retired bourbon casks, giving it a
                warm, spicy and oak finish. golden caramel in colour, this
                reposado is easy to sip with friends or blend as a cocktail.
              </div>
            </div>

            <div className="border-b border-gold border-dashed pt-10 pb-12">
              <div
                className={cx(pdp.pdp__stat_heading, "text-gold text-md mb-1")}
              >
                Tasting Notes
              </div>
              <div
                className={cx(pdp.pdp__stat)}
                dangerouslySetInnerHTML={{ __html: data.notes }}
              />
            </div>
          </div>

          {type === "reposado" ? (
            <div className="grid grid-cols-2 md:grid-cols-3 md:mb-20">
              <div className="hidden md:block text-center border-r border-dashed border-gold pt-10 pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-1"
                  )}
                >
                  Tasting Notes
                </div>
                <div className={cx(pdp.pdp__stat)}>
                  caramel, <br />
                  spicy  oak finish
                </div>
              </div>
              <div className="text-center border-r border-dashed border-gold pt-10 pb-14 md:pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-2 md:mb-1"
                  )}
                >
                  Agave
                </div>
                <div className={cx(pdp.pdp__stat_lg)}>100%</div>
              </div>
              <div className="text-center pt-10 pb-14 md:pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-2 md:mb-1"
                  )}
                >
                  Barrel-Aged
                </div>
                <div className={cx(pdp.pdp__stat_lg)}>3MO.</div>
              </div>
            </div>
          ) : type === "blanco" ? (
            <div className="grid grid-cols-2 md:grid-cols-3 md:mb-20">
              <div className="hidden md:block text-center border-r border-dashed border-gold pt-10 pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-1"
                  )}
                >
                  Tasting Notes
                </div>
                <div className={cx(pdp.pdp__stat)}>
                  Sippable & <br />
                  Agave-Forward
                </div>
              </div>
              <div className="text-center border-r border-dashed border-gold pt-10 pb-14 md:pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-2 md:mb-1"
                  )}
                >
                  Agave
                </div>
                <div className={cx(pdp.pdp__stat_lg)}>100%</div>
              </div>
              <div className="text-center pt-10 pb-14 md:pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-2 md:mb-1"
                  )}
                >
                  Agave-Aged
                </div>
                <div className={cx(pdp.pdp__stat_lg)}>8YR.</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </ThemeContext.Consumer>
  );
};

import React, { useState } from "react";

import { Seo } from "../components/seo";
import { renderSections } from "../lib/renderSections";

import * as styles from "../sections/plpProduct.module.scss";
import cx from "classnames";

import plpBlanco from "../../static/images/plp--blanco.jpg";
import plpBlancoHover from "../../static/images/plp--blanco--hover.jpg";
import plpBlancoMobile from "../../static/images/plp--blanco--mobile.jpg";
import plpBlanco5 from "../../static/images/celaya-labelbadge-05.png";
import plpBlanco6 from "../../static/images/celaya-labelbadge-06.png";
import plpBlanco7 from "../../static/images/celaya-labelbadge-07.png";

import plpAnejo from "../../static/images/plp--anejo.jpg";
import plpAnejoHover from "../../static/images/plp--anejo--hover.jpg";
import plpAnejoMobile from "../../static/images/plp--anejo--mobile.jpg";

import plpReposado from "../../static/images/plp--reposado.jpg";
import plpReposadoHover from "../../static/images/plp--reposado--hover.jpg";
import plpReposadoMobile from "../../static/images/plp--reposado--mobile.jpg";

import plpBundleMixed from "../../static/images/plp--bundle--mixed.png";
import plpBundleBlanco from "../../static/images/plp--bundle--blanco.png";
import plpBundleReposado from "../../static/images/plp--bundle--reposado.png";
import plpCase from "../../static/images/Case-Deal.png";

import plpStory1 from "../../static/images/plp--story-1.jpg";
import plpStory2 from "../../static/images/plp--story-2.jpg";
import plpStory3 from "../../static/images/plp--story-3.jpg";

import { PageLink } from "../components/link";
import { StoreContext } from "../context/store-context";
import ThemeContext from "../context/ThemeContext";

export const PLP = ({ data }) => {
  const [productHovered, setProductHovered] = useState(-1);

  const hoverProduct = i => {
    setProductHovered(i);
  };

  const unHoverProduct = () => {
    setProductHovered(-1);
  };

  const { addVariantToCart, loading } = React.useContext(StoreContext);

  function addToCart(shopify) {
    addVariantToCart("gid://shopify/ProductVariant/" + shopify, 1);
  }

  return (
    <ThemeContext.Consumer>
      {theme => (
        <>
          <section className="container flex flex-col items-center pt-10 md:pt-32 mt-20 mb-10 md:mb-28 relative px-4 md:px-0">
            <h1
              className={cx(
                styles.plp__h1,
                "font-serif text-center lowercase leading-none"
              )}
            >
              It’s more than a spirit, <br className="hidden md:block" />
              it’s an experience of family.
            </h1>
          </section>
          <div className="relative" data-index={1} key={1}>
            <section className="container absolute flex items-end md:items-center top-0 bottom-10 w-full md:w-auto">
              <div className="grid grid-cols-1 md:grid-cols-5 w-full md:w-auto">
                <div className="col-span-1 md:col-span-2">
                  <div className="p-4 bg-offWhite relative">
                    <img
                      src={plpBlanco5}
                      alt="100 Percent Agave - Blanco"
                      className="absolute top-0 md:top-2 right-6 md:right-10 w-16 md:w-20"
                    />
                    <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                      <div className="flex justify-center items-center w-full h-full border border-gold p-4">
                        <div className="pt-2 pb-2 md:pt-6 md:pb-6 text-center w-full">
                          <p className="font-historical text-lg">
                            Tequila Blanco
                          </p>
                          <p className="font-serif text-gold text-md">$49</p>
                          <div className="md:grid grid-cols-3 pt-6 pb-8 hidden">
                            <div className="border-r border-dashed border-gold">
                              <div className="text-gold text-2xs pb-1">
                                Flavor Notes
                              </div>
                              <div className="font-sans text-xs leading-none pb-3">
                                Sippable & <br />
                                Agave-Forward
                              </div>
                            </div>
                            <div className="border-r border-dashed border-gold">
                              <div className="text-gold text-2xs pb-1">
                                Agave
                              </div>
                              <div
                                className={cx(
                                  styles.plp__stat,
                                  "font-sans pb-3"
                                )}
                              >
                                100%
                              </div>
                            </div>
                            <div>
                              <div className="text-gold text-2xs pb-1">
                                Agave Aged
                              </div>
                              <div
                                className={cx(
                                  styles.plp__stat,
                                  "font-sans pb-3"
                                )}
                              >
                                8YR.
                              </div>
                            </div>
                          </div>
                          <div className="mb-6 font-serif text-xs lg:px-10 hidden md:block">
                            Agave-forward, this tequila is bottled soon after
                            distillation with crisp notes of citrus & spice.
                          </div>
                          <PageLink
                            link="/shop/tequila-blanco"
                            onMouseEnter={() => {
                              setProductHovered(1);
                            }}
                            onMouseLeave={unHoverProduct}
                            className="text-xs bg-black text-white font-serif lowercase mt-6 md:mt-0 w-full md:w-9/12 md:px-14 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out inline-block"
                          >
                            Shop Blanco
                          </PageLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <img
              src={plpBlanco}
              alt=""
              className={cx(
                productHovered === 1 ? "hidden" : "hidden md:block"
              )}
            />
            <img
              src={plpBlancoHover}
              alt=""
              className={cx(
                productHovered === 1 ? "hidden md:block" : "hidden"
              )}
            />
            <img src={plpBlancoMobile} alt="" className="block md:hidden" />
          </div>

          <div className="relative" data-index={2} key={2}>
            <section className="container absolute flex items-end md:items-center top-0 bottom-10 w-full md:w-auto">
              <div className="grid grid-cols-1 md:grid-cols-5 w-full md:w-auto">
                <div className="col-span-1 md:col-span-2 md:col-start-4">
                  <div className="p-4 bg-offWhite relative">
                    <img
                      src={plpBlanco6}
                      alt="Barrel Aged Reposado"
                      className="absolute bottom-2 md:bottom-28 right-2 w-16 md:w-20"
                    />
                    <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                      <div className="flex justify-center items-center w-full h-full border border-gold p-4">
                        <div className="pt-2 pb-2 md:pt-6 md:pb-6 text-center w-full">
                          <p className="font-historical text-lg">
                            Tequila Reposado
                          </p>
                          <p className="font-serif text-gold text-md">$55</p>
                          <div className="hidden md:grid grid-cols-3 pt-6 pb-8">
                            <div className="border-r border-dashed border-gold">
                              <div className="text-gold text-2xs pb-1">
                                Flavor Notes
                              </div>
                              <div className="font-sans text-xs leading-none pb-3">
                                caramel, spicy 
                                <br />
                                oak finish
                              </div>
                            </div>
                            <div className="border-r border-dashed border-gold">
                              <div className="text-gold text-2xs pb-1">
                                Agave
                              </div>
                              <div
                                className={cx(
                                  styles.plp__stat,
                                  "font-sans pb-3"
                                )}
                              >
                                100%
                              </div>
                            </div>
                            <div>
                              <div className="text-gold text-2xs pb-1">
                                Barrel Aged
                              </div>
                              <div
                                className={cx(
                                  styles.plp__stat,
                                  "font-sans pb-3"
                                )}
                              >
                                3MO.
                              </div>
                            </div>
                          </div>
                          <div className="mb-6 font-serif text-xs lg:px-10 hidden md:block">
                            Soft, with notes of dried fruits, this tequila is
                            warm and spicy with an oak finish.
                          </div>
                          <PageLink
                            link="/shop/tequila-reposado"
                            onMouseEnter={() => {
                              setProductHovered(2);
                            }}
                            onMouseLeave={unHoverProduct}
                            className="text-xs bg-black text-white font-serif lowercase mt-6 md:mt-0 w-full md:w-9/12 md:px-14 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out inline-block"
                          >
                            Shop Reposado
                          </PageLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <img
              src={plpReposado}
              alt=""
              className={cx(
                productHovered === 2 ? "hidden" : "hidden md:block"
              )}
            />
            <img
              src={plpReposadoHover}
              alt=""
              className={cx(
                productHovered === 2 ? "hidden md:block" : "hidden"
              )}
            />
            <img src={plpReposadoMobile} alt="" className="block md:hidden" />
          </div>

          <div className="relative" data-index={3} key={3}>
            <section className="container absolute flex items-end md:items-center top-0 bottom-10 w-full md:w-auto">
              <div className="grid grid-cols-1 md:grid-cols-5 w-full md:w-auto">
                <div className="col-span-1 md:col-span-2">
                  <div className="p-4 bg-offWhite relative">
                    <img
                      src={plpBlanco7}
                      alt="Dark - Barrel - Aged - Anejo"
                      className="absolute top-10 md:top-16 left-2 w-16 md:w-20"
                    />
                    <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                      <div className="flex justify-center items-center w-full h-full border border-gold p-4">
                        <div className="pt-2 pb-2 md:pt-6 md:pb-6 text-center w-full">
                          <p className="font-historical text-lg">
                            Tequila Añejo
                          </p>
                          <p className="font-serif text-gold text-md">$89</p>
                          <div className="hidden md:grid grid-cols-3 pt-6 pb-8">
                            <div className="border-r border-dashed border-gold">
                              <div className="text-gold text-2xs pb-1">
                                Flavor Notes
                              </div>
                              <div className="font-sans text-xs leading-none pb-3">
                                strong spice <br />
                                & barrel notes
                              </div>
                            </div>
                            <div className="border-r border-dashed border-gold">
                              <div className="text-gold text-2xs pb-1">
                                Agave
                              </div>
                              <div
                                className={cx(
                                  styles.plp__stat,
                                  "font-sans pb-3"
                                )}
                              >
                                100%
                              </div>
                            </div>
                            <div>
                              <div className="text-gold text-2xs pb-1">
                                Barrel Aged
                              </div>
                              <div
                                className={cx(
                                  styles.plp__stat,
                                  "font-sans pb-3"
                                )}
                              >
                                12MO.
                              </div>
                            </div>
                          </div>
                          <div className="mb-6 font-serif text-xs lg:px-10 hidden md:block">
                            Darkened with a year of resting in bourbon barrels,
                            this tequila is layered and complex.
                          </div>
                          <PageLink
                            link="/shop/tequila-anejo"
                            onMouseEnter={() => {
                              setProductHovered(3);
                            }}
                            onMouseLeave={unHoverProduct}
                            className="text-xs bg-black text-white font-serif lowercase mt-6 md:mt-0 w-full md:w-9/12 md:px-14 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out inline-block"
                          >
                            Shop Añejo
                          </PageLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <img
              src={plpAnejo}
              alt=""
              className={cx(
                productHovered === 3 ? "hidden" : "hidden md:block"
              )}
            />
            <img
              src={plpAnejoHover}
              alt=""
              className={cx(
                productHovered === 3 ? "hidden md:block" : "hidden"
              )}
            />
            <img src={plpAnejoMobile} alt="" className="block md:hidden" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 px-10 pt-12 items-center mt-20">
            <div className="col-span-7">
              <img src={plpBundleMixed} alt="Mixed Bundle" />
            </div>
            <div className="col-span-4">
              <div className="p-4 bg-offWhite relative">
                <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                  <div className="flex justify-center items-center w-full h-full border border-gold p-4">
                    <div className="pt-2 pb-2 md:pt-10 md:pb-10 text-center w-full">
                      <p className="font-historical text-sm md:text-lg">
                        Mixed Bundle
                      </p>
                      <p className="font-serif text-gold text-md block mb-4 md:mb-0">
                        $174
                      </p>
                      <div className="font-serif text-xs lowercase">
                        Save 10%
                      </div>
                      <div className="w-6/12 pt-6 ml-auto mr-auto mb-6 border-b border-dashed border-gold hidden md:block" />
                      <div className="mb-6 font-serif text-xs hidden md:block">
                        Tasting party? Invite your friends over and try all
                        three of Celaya’s premium Tequilas.
                      </div>
                      <PageLink
                        link="/shop/bundle"
                        className="text-xs bg-black text-white font-serif lowercase mt-6 md:mt-0 w-full md:w-9/12 md:px-14 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out inline-block"
                      >
                        Shop Bundle
                      </PageLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 px-10 items-center mt-20 md:mt-0">
            <div className="col-span-7 md:hidden">
              <img src={plpBundleBlanco} alt="Blanco Bundle" />
            </div>
            <div className="col-span-4 md:col-start-2">
              <div className="p-4 bg-offWhite relative">
                <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                  <div className="flex justify-center items-center w-full h-full border border-gold p-4">
                    <div className="pt-2 pb-2 md:pt-10 md:pb-10 text-center w-full">
                      <p className="font-historical text-sm md:text-lg">
                        Blanco Bundle
                      </p>
                      <p className="font-serif text-gold text-md block mb-4 md:mb-0">
                        $132
                      </p>
                      <div className="font-serif text-xs lowercase">
                        Save 10%
                      </div>
                      <div className="w-6/12 pt-6 ml-auto mr-auto mb-6 border-b border-dashed border-gold hidden md:block" />
                      <div className="mb-6 font-serif text-xs hidden md:block">
                        With 3 bottles of our vibrant but smooth Blanco, you’ll
                        have plenty to mix, sip and share!
                      </div>
                      <PageLink
                        link="/shop/bundle?type=blanco"
                        className="text-xs bg-black text-white font-serif lowercase mt-6 md:mt-0 w-full md:w-9/12 md:px-14 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out inline-block"
                      >
                        Shop Bundle
                      </PageLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-7 hidden md:block">
              <img src={plpBundleBlanco} alt="Blanco Bundle" />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 px-10 items-center mt-20 md:mt-0">
            <div className="col-span-7">
              <img src={plpBundleReposado} alt="Reposado Bundle" />
            </div>
            <div className="col-span-4">
              <div className="p-4 bg-offWhite relative">
                <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                  <div className="flex justify-center items-center w-full h-full border border-gold p-4">
                    <div className="pt-2 pb-2 md:pt-10 md:pb-10 text-center w-full">
                      <p className="font-historical text-sm md:text-lg">
                        Reposado Bundle
                      </p>
                      <p className="font-serif text-gold text-md block mb-4 md:mb-0">
                        $149
                      </p>
                      <div className="font-serif text-xs lowercase">
                        Save 10%
                      </div>
                      <div className="w-6/12 pt-6 ml-auto mr-auto mb-6 border-b border-dashed border-gold hidden md:block" />
                      <div className="mb-6 font-serif text-xs hidden md:block">
                        A table is set for hospitality when placed with bottles
                        of our soft, rounded and spicy Reposado.
                      </div>
                      <PageLink
                        link="/shop/bundle?type=reposado"
                        className="text-xs bg-black text-white font-serif lowercase mt-6 md:mt-0 w-full md:w-9/12 md:px-14 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out inline-block"
                      >
                        Shop Bundle
                      </PageLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 px-10 items-center mt-20 md:mt-0">
            <div className="col-span-7 md:hidden">
              <img src={plpCase} alt="The Case Deal" />
            </div>
            <div className="col-span-4 md:col-start-2">
              <div className="p-4 bg-offWhite relative">
                <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                  <div className="flex justify-center items-center w-full h-full border border-gold p-4">
                    <div className="pt-2 pb-2 md:pt-10 md:pb-10 text-center w-full">
                      <p className="font-historical text-sm md:text-lg">
                        The Case Deal
                      </p>
                      <p className="font-serif text-gold text-md block mb-4 md:mb-0">
                        $254 - $346
                      </p>
                      <div className="font-serif text-xs lowercase">
                        Save $40
                      </div>
                      <div className="w-6/12 pt-6 ml-auto mr-auto mb-6 border-b border-dashed border-gold hidden md:block" />
                      <div className="mb-6 font-serif text-xs hidden md:block">
                        Stock up and be ready for any event with 6 bottles of
                        Celaya tequila.
                      </div>
                      <PageLink
                        link="/shop/case-deal"
                        className="text-xs bg-black w-full md:w-9/12 mt-6 md:mt-0 text-white font-serif lowercase md:px-8 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out inline-block"
                      >
                        Shop Case Deal
                      </PageLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-7 hidden md:block">
              <img src={plpCase} alt="The Case Deal" />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 md:px-10 py-20 md:py-40 mb-20 items-center overflow-hidden">
            <div className="col-span-1 md:col-span-6 px-10 md:px-0 mb-16 md:mb-0">
              <p className={cx(styles.plp__story, "leading-snug")}>
                The storied history of Celaya Tequila begins in Mexico, on the
                porch of a rural Sonora ranch in the late 1800s. There sits our
                great-great-grandfather, Jose Celaya, in the shade of the
                oppressive Mexican sun, sampling the very first batch of his
                homemade tequila. He is pleased.
              </p>
              <div className="mt-4 md:mt-8 hover:opacity-80 transition-opacity duration-200 ease-in-out">
                <PageLink
                  className="font-serif lowercase font-semibold text-xs md:text-xs leading-none font-bold text-darkGray inline-block"
                  link="/story"
                  _type="internalLink"
                >
                  Our Story
                  <div className={cx(styles.plp__divider, "bg-darkGray")} />
                </PageLink>
              </div>
            </div>
            <div
              className="col-span-1 md:col-span-5 md:col-start-8 relative h-96 pl-20 md:pl-0"
              onMouseEnter={() => {
                setProductHovered(4);
              }}
              onMouseLeave={unHoverProduct}
              data-index={4}
              key={4}
            >
              <img
                src={plpStory1}
                alt=""
                className={cx(
                  productHovered === 4 ? "-rotate-6" : "-rotate-3",
                  "absolute top-0 z-30 transform-gpu transition-all"
                )}
              />
              <img
                src={plpStory2}
                alt=""
                className={cx(
                  productHovered === 4 ? "-rotate-12" : "",
                  "absolute z-20 transform-gpu transition-all"
                )}
              />
              <img
                src={plpStory3}
                alt=""
                className={cx(
                  productHovered === 4 ? "-rotate-12" : "-rotate-12",
                  "absolute z-10 transform-gpu w-8/12 transition-margin ease-in duration-500"
                )}
              />
            </div>
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  );
};

export default PLP;

import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as styles from "./legal.module.scss";
import cx from "classnames";
import closestIndexTo from "date-fns/closestIndexTo/index";
// import Stencil_Type from "../icons/stencil_type";
import C_Logo_Inverted from "../icons/c_logo-inverted";
import { PageLink } from "../components/link";
import PortableText from "../components/portableText";

export const Legal  = ({ data }) => {


  return (
    <section
    className={cx(styles.legal_container, "flex flex-col items-center pt-40 md:pt-64 relative pb-20" )}
    >
        {/* <div className={cx(styles.sectionHeader, "fixed top-0 left-0 right-0 w-full flex justify-center items-center w-full justify-center items-center md:h-28 z-0 bg-offWhite px-4 md:px-0")}>
                <div className={cx(styles.sectionHeaderInner, "flex justify-center items-center h-full border-b border-dashed border-gold pt-6 md:pt-0 pb-4 md:pb-0")}>
                    <C_Logo_Inverted className={cx(styles.sectionHeaderLogo)} />
                </div>

            </div> */}
         <div className="w-full md:w-1/2">
             <h1 className="text-center font-serif lowercase text-base">{data.heading}</h1>
             <div className="font-historical text-xs mt-4">{data.introText}</div>
             <h3 className="font-historical text-base mt-10">{data.subheading}</h3>

             {data.sections.map((section) => (
                 <div className="py-2.5">
                     <PortableText
                        className="text-lg font-serif"
                        blocks={section}
                    />
                 </div>
              ))}

         </div>

    </section>
  );
};

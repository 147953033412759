import React from "react";

const Star = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m8 .538 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8 11.594 3.298 15.01l1.796-5.528L.392 6.066h5.812L8 .538Z"
      fill="#000"
    />
  </svg>
);

export default Star;

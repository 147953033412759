import React, { useEffect } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./storyHero.module.scss";
import cx from "classnames";

export const StoryHero = ({ data }) => {
  useEffect(() => {}, []);

  return (
    <section
      className={cx(
        styles.story__heroContainer,
        "pb-8 relative flex justify-center items-center w-full h-full md:mb-24"
      )}
    >
      <div className="container">
        <div className="flex flex-col w-full">
          <div className="heading font-serif uppercase text-xl md:text-3xl lg:text-4xl w-full md:w-8/12 z-10 mt-64 md:mt-0 mb-10 md:mb-20 text-white transition-opacity duration-500 ease-in-out">
            {data.heading}
          </div>
          <div className="heading font-serif uppercase text-md md:text-xl w-3/4 md:w-1/2 z-10 text-white transition-opacity duration-500 ease-in-out">
            {data.subheading}
          </div>
        </div>
      </div>

      <SanityImage
        className={cx(
          styles.story__heroImage,
          "top-0 left-0 h-full w-full object-cover bg-black absolute z-0 object-right md:object-center"
        )}
        alt={"How Celaya started making Tequila"}
        asset={data.image.asset}
        width={3840}
        height={2160}
      />
    </section>
  );
};

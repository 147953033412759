import React from "react";

const Stencil_Type = ({ className, height, width }) => (

    <svg className={className} width="548" height="541" viewBox="0 0 548 541" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52.5309 69.9546C48.5909 66.38 45.4654 61.9991 43.3664 57.1092C41.2673 52.2193 40.2439 46.9348 40.3655 41.6142C40.3117 36.1105 41.3937 30.655 43.544 25.5892C45.6943 20.5233 48.8666 15.9563 52.8621 12.1739C59.67 5.91921 68.2505 1.93393 77.4188 0.768381L77.6447 1.98878C71.3986 5.26118 66.0444 10.0088 62.0464 15.82C57.3911 22.8206 54.961 31.0666 55.0754 39.4747C55.0152 48.281 57.6415 56.896 62.6035 64.1691C67.0872 70.6216 73.091 75.8689 80.0838 79.4467L79.9633 80.5466C69.8519 80.3903 60.1266 76.6353 52.5309 69.9546ZM98.0006 13.9367C93.6189 9.09499 88.4587 5.02097 82.7336 1.8833L82.839 0.662903C91.4199 0.666748 99.9167 2.356 107.847 5.6349C108.946 12.1738 108.841 19.3607 110.046 29.4404L108.54 29.6663C105.751 23.9718 102.206 18.6806 98.0006 13.9367ZM85.1728 79.3562C90.029 77.585 94.5497 75.0021 98.5426 71.7175C103.233 68.0025 107.207 63.4637 110.271 58.3232L111.777 58.6546C110.889 62.6473 109.775 70.949 108.766 75.228C101.364 78.3592 93.4581 80.1253 85.4287 80.4411L85.1728 79.3562Z" fill="#C8A677"/>
    <path d="M126.758 78.1359C130.291 77.4279 133.504 75.6063 135.927 72.9379C138.141 70.1656 139.134 65.4045 139.465 59.875C139.691 56.9973 139.917 50.835 139.917 46.7067V41.0567C139.917 32.981 139.797 25.8997 139.465 20.5811C139.134 15.2626 138.592 11.9479 136.921 9.40158C135.553 7.39299 133.634 5.82321 131.395 4.88157C129.364 4.05885 127.249 3.46264 125.087 3.10371L125.207 1.77784C128.851 2.00384 141.242 2.00388 144.118 2.00388C147.551 2.00388 151.194 2.00386 154.657 1.8984L154.763 3.40507C153.106 7.72921 152.67 17.5678 152.67 36.5518V42.8648C152.67 49.4941 152.67 57.0274 153.212 63.4458C153.754 69.8642 155.32 73.5104 158.09 75.8458C159.436 76.9677 161.023 77.7619 162.727 78.166L162.622 79.4919C155.877 79.4919 150.457 79.3864 145.473 79.3864C139.285 79.3864 130.868 79.4919 126.894 79.6125L126.758 78.1359ZM174.757 44.8234C172.541 42.8792 169.813 41.6136 166.898 41.1773C163.935 40.722 160.936 40.5354 157.939 40.6198V38.405C161.193 38.4685 164.446 38.3226 167.681 37.9681C170.016 37.6791 172.249 36.8428 174.2 35.5273C176.546 33.8848 178.282 31.5114 179.139 28.7774L180.644 29.0034C180.418 31.866 180.208 36.5367 180.208 40.1828C180.208 43.829 180.208 47.8067 180.313 50.2475L178.807 50.3529C177.802 48.28 176.43 46.4067 174.757 44.8234ZM177.302 11.1794C172.249 7.23599 166.337 4.54141 160.047 3.31467L160.153 1.98879C167.35 1.88333 172.439 1.76276 176.413 1.76276C180.84 1.76276 184.273 1.88334 186.817 2.09427C187.916 8.73869 187.585 12.6409 188.805 22.6904L187.374 22.9014C184.734 18.4299 181.324 14.4614 177.302 11.1794ZM168.117 79.3563L168.012 78.0305C172.81 76.389 177.243 73.8305 181.066 70.4971C185.147 67.0695 188.593 62.9484 191.244 58.3232L192.674 58.5491C191.244 66.0825 190.461 73.9323 189.241 79.2509C187.736 79.3563 183.219 79.4768 181.066 79.4768C176.865 79.4768 172.544 79.4768 168.117 79.3563Z" fill="#C8A677"/>
    <path d="M206.24 78.1359C209.777 77.4289 212.996 75.6074 215.424 72.9379C217.637 70.1656 218.631 65.4045 218.962 59.875C219.188 56.9973 219.399 50.835 219.399 46.7067V37.9681C219.399 22.133 219.188 13.3943 216.086 8.96468C212.985 4.53507 208.558 3.6461 205.683 3.08863L205.803 1.76276C210.215 1.76276 218.405 2.09427 224.714 2.09427C230.736 2.09427 240.191 1.88329 245.175 1.76276L245.28 3.08863C237.752 4.76104 234.56 8.18118 233.341 12.5053C232.227 16.3775 232.121 24.0163 232.121 34.6383V42.7291C232.121 49.3585 232.121 56.8919 232.678 63.3103C233.235 69.7287 234.892 73.6008 237.647 75.8156C238.973 76.8741 240.515 77.63 242.164 78.0305L242.043 79.3563C235.629 79.3563 229.652 79.2509 224.909 79.2509C218.706 79.2509 210.305 79.3563 206.315 79.4768L206.24 78.1359ZM247.614 79.3563L247.494 78.0305C252.441 76.291 256.991 73.5813 260.879 70.0601C264.858 66.4914 268.287 62.3536 271.057 57.7808L272.562 57.9917C271.222 65.525 270.229 73.7214 269.024 79.2509C267.518 79.3563 263.152 79.4768 260.939 79.4768C256.603 79.4768 251.965 79.4768 247.539 79.3563H247.614Z" fill="#C8A677"/>
    <path d="M276.176 78.1359C278.101 77.2559 279.891 76.1042 281.49 74.7158C287.242 69.8342 291.111 64.4102 295.869 55.7769L297.194 56.0029C296.971 57.4334 296.86 58.8793 296.863 60.3271C296.863 67.6194 298.368 71.5066 301.38 74.9267C302.891 76.3777 304.707 77.4738 306.694 78.1359L306.574 79.4768C302.057 79.4768 294.303 79.2509 289.109 79.2509C283.914 79.2509 277.38 79.4768 276.266 79.4768L276.176 78.1359ZM327.833 78.1359C331.929 77.1415 336.024 74.4898 336.867 70.7231C336.603 67.6129 335.857 64.5629 334.654 61.6831C333.329 58.1424 331.447 54.0442 329.791 50.2776C325.695 50.1721 321.163 50.1721 315.849 50.1721C310.534 50.1721 301.696 50.1721 297.781 50.2776L299.287 47.7313C303.382 47.9573 311.452 47.9573 315.984 47.9573C320.516 47.9573 324.943 47.9573 328.827 47.7313C324.506 37.9982 320.2 28.5816 313.334 14.7503C315.547 9.32633 317.655 3.6763 318.86 0.135629L320.531 0.0301514C323.949 8.1059 327.382 15.9707 330.92 23.8204C337.665 38.9926 346.413 58.2479 351.381 66.2181C354.814 71.7476 359.015 76.7648 363.878 78.0606L363.772 79.5672C361.785 79.5672 352.932 79.3413 346.292 79.3413C341.881 79.3413 334.021 79.5672 328.044 79.5672L327.833 78.1359Z" fill="#C8A677"/>
    <path d="M374.282 78.1358C376.96 77.5178 379.481 76.3535 381.689 74.7158C384.46 72.6064 385.89 68.689 386.447 65.525C387.207 60.1347 387.579 54.6967 387.561 49.253C385.666 42.29 383.147 35.5126 380.033 29.0034C377.12 22.8015 373.321 17.0562 368.756 11.9479C364.33 7.08131 359.346 4.09816 357.133 4.09816C356.5 4.10413 355.869 4.17474 355.251 4.30902L354.919 2.98324C358.229 1.64849 361.755 0.933477 365.323 0.873865C371.963 0.873865 377.594 2.54625 382.909 9.52214C386.813 14.8164 390.045 20.5759 392.53 26.668C395.611 33.7126 398.202 40.9619 400.284 48.3641C400.389 63.2048 401.052 68.0714 403.491 72.275C405.147 75.0472 408.354 77.036 413.322 78.1358L413.217 79.4768C408.7 79.3563 399.275 79.1454 393.855 79.1454C387.667 79.1454 378.151 79.3563 374.387 79.4768L374.282 78.1358ZM401.819 35.5273C403.325 27.4515 405.358 17.0404 409.122 10.7275C412.449 5.09251 417.207 0.768387 424.283 0.768387C427.823 0.763304 431.257 1.97619 434.01 4.20364L433.573 5.42404C426.861 7.04758 420.826 10.7361 416.319 15.9707C410.958 21.9366 406.485 28.645 403.039 35.8889L401.819 35.5273Z" fill="#C8A677"/>
    <path d="M422.943 78.1359C424.871 77.2594 426.661 76.1074 428.258 74.7158C434.01 69.8342 437.879 64.4102 442.637 55.7769L443.962 56.0029C443.739 57.4334 443.628 58.8793 443.63 60.3271C443.63 67.6194 445.136 71.5066 448.147 74.9267C449.659 76.3777 451.475 77.4738 453.462 78.1359L453.357 79.4768C448.84 79.4768 441.071 79.2509 435.876 79.2509C430.682 79.2509 424.148 79.4768 423.049 79.4768L422.943 78.1359ZM474.601 78.1359C478.696 77.1415 482.792 74.4898 483.635 70.7231C483.357 67.6153 482.61 64.5675 481.421 61.6831C480.081 58.1424 478.215 54.0442 476.543 50.2776C472.463 50.1721 467.916 50.1721 462.616 50.1721C457.076 50.1721 448.448 50.1721 444.474 50.2776L445.904 47.7313C449.999 47.9573 458.069 47.9573 462.616 47.9573C467.163 47.9573 471.575 47.9573 475.444 47.7313C471.123 37.9982 466.817 28.5816 459.951 14.7503C462.165 9.32633 464.272 3.6763 465.492 0.135629L467.148 0.0301514C470.581 8.1059 473.999 15.9707 477.537 23.8204C484.297 38.9926 493.03 58.2479 498.013 66.2181C501.431 71.7476 505.647 76.7648 510.51 78.0606L510.39 79.5672C508.402 79.5672 499.549 79.3413 492.924 79.3413C488.498 79.3413 480.639 79.5672 474.676 79.5672L474.601 78.1359Z" fill="#C8A677"/>
    <path d="M-0.0301514 151.541C1.1894 141.687 1.18936 137.378 2.18306 130.734C4.60711 130.508 6.9409 130.402 10.3587 130.402C12.5719 130.402 17.1038 130.508 21.4249 130.508L21.5303 131.834C17.0691 134.075 13.0296 137.072 9.59077 140.693C6.39609 144.016 3.64318 147.738 1.4002 151.767L-0.0301514 151.541ZM14.5593 206.775C17.243 206.153 19.7698 204.983 21.982 203.34C24.7373 201.246 26.1827 197.313 26.7398 194.149C27.2818 191.136 27.8389 184.522 27.8389 167.813C27.8389 162.615 27.7334 153.65 27.613 149.221C27.5076 143.013 27.2817 137.378 26.8451 130.628H41.2238C40.8926 135.826 40.7872 140.798 40.6668 148.332C40.5614 154.193 40.5614 161.892 40.5614 167.376C40.5614 180.77 40.7872 188.741 41.555 193.939C41.8363 196.385 42.5879 198.754 43.7683 200.914C45.4245 203.672 48.6314 205.675 53.615 206.775L53.4946 208.101C48.9777 207.996 39.5676 207.77 34.1474 207.77C27.9443 207.77 18.4438 207.996 14.6798 208.101L14.5593 206.775ZM58.8094 140.693C55.3105 137.042 51.1914 134.043 46.644 131.834L46.7494 130.508C51.1759 130.508 56.2649 130.402 58.147 130.402C61.3539 130.402 63.8984 130.508 66.3225 130.734C67.3162 137.378 67.3161 141.687 68.5357 151.541L67.0301 151.767C64.7566 147.758 62.0064 144.04 58.8395 140.693H58.8094Z" fill="#C8A677"/>
    <path d="M79.9634 206.715C83.4987 206.002 86.7154 204.182 89.1476 201.517C91.3609 198.745 92.3547 193.984 92.6859 188.454C92.9117 185.577 93.1225 179.414 93.1225 175.286V169.636C93.1225 161.56 93.0171 154.464 92.6859 149.16C92.3547 143.857 91.7976 140.527 90.1414 137.981C88.7745 135.972 86.8552 134.402 84.6157 133.461C82.5862 132.633 80.4701 132.037 78.3073 131.683L78.4126 130.357C82.0712 130.583 94.4474 130.583 97.3231 130.583C100.756 130.583 104.415 130.583 107.862 130.463L107.983 131.969C106.327 136.278 105.875 146.132 105.875 165.116V171.429C105.875 178.073 105.875 185.607 106.432 192.025C106.989 198.444 108.54 202.09 111.295 204.41C112.639 205.514 114.214 206.302 115.903 206.715L115.782 208.041C109.037 208.041 103.617 207.92 98.6481 207.92C92.4449 207.92 84.0436 208.041 80.0537 208.146L79.9634 206.715ZM127.947 173.463C125.736 171.508 123.006 170.237 120.088 169.802C117.123 169.361 114.126 169.179 111.13 169.259V167.044C114.378 167.104 117.627 166.953 120.856 166.593C123.196 166.316 125.436 165.484 127.39 164.167C129.712 162.509 131.421 160.131 132.254 157.402L133.759 157.628C133.533 160.506 133.307 165.161 133.307 168.807C133.307 172.453 133.307 176.446 133.413 178.887L131.983 178.992C130.982 176.921 129.615 175.047 127.947 173.463ZM130.477 139.804C125.419 135.871 119.509 133.182 113.222 131.954L113.343 130.628C120.525 130.508 125.614 130.402 129.604 130.402C134.03 130.402 137.448 130.508 139.992 130.734C141.107 137.378 140.775 141.28 141.98 151.315L140.549 151.541C137.924 147.064 134.523 143.09 130.507 139.804H130.477ZM121.308 207.996L121.187 206.67C125.983 205.024 130.415 202.466 134.241 199.137C138.327 195.714 141.773 191.592 144.419 186.963L145.925 187.189C144.419 194.722 143.711 202.572 142.492 207.875C140.986 207.996 136.469 208.101 134.301 208.101C130.07 208.101 125.764 208.101 121.338 207.996H121.308Z" fill="#C8A677"/>
    <path d="M166.341 194.149C161.041 187.957 158.165 179.761 158.165 170.133C158.165 154.524 165.242 142.576 176.519 135.48C181.786 132.286 187.664 130.233 193.773 129.453L193.999 130.674C181.608 137.423 172.92 151.029 172.92 167.964C172.92 186.782 180.659 197.419 189.482 205.63L189.151 206.625C180.331 205.242 172.266 200.831 166.341 194.149ZM194.104 224.042C186.366 220.938 179.726 218.618 173.206 217.729L172.981 216.524L194.767 209.096C216.117 201.788 222.756 184.642 222.756 170.028C222.756 151.209 213.135 137.152 199.088 130.508L199.193 129.287C208.705 129.248 217.905 132.676 225.075 138.93C233.597 146.463 237.466 157.01 237.466 168.476C237.466 177.772 234.711 186.556 229.938 192.583C223.868 199.829 215.838 205.17 206.812 207.966L191.665 213.058C196.545 213.582 201.384 214.433 206.149 215.604C211.795 217.06 217.579 217.914 223.404 218.151C229.49 218.138 235.368 215.934 239.966 211.943L240.854 212.832C234.997 221.028 225.481 228.999 213.421 228.999C206.827 229.029 200.97 226.814 194.104 224.042Z" fill="#C8A677"/>
    <path d="M265.456 202.195C259.915 197.329 256.934 190.353 256.934 181.493C256.934 178.058 257.16 171.64 257.16 165.447C257.279 159.415 257.093 153.381 256.603 147.367C256.045 143.706 255.383 139.729 252.402 136.821C249.977 134.454 247.018 132.707 243.775 131.728L243.88 130.402C248.969 130.508 256.151 130.734 262.685 130.734C270.65 130.734 277.395 130.508 282.258 130.402L282.484 131.728C279.551 132.561 276.857 134.079 274.625 136.158C272.327 138.796 270.897 142.079 270.53 145.559C269.987 151.187 269.766 156.841 269.867 162.494V170.359C269.867 174.789 269.867 178.766 270.198 182.413C270.755 188.439 272.969 193.366 276.627 196.575C280.95 200.013 286.362 201.783 291.879 201.562C297.106 201.661 302.225 200.076 306.484 197.042C310.752 193.909 313.836 189.421 315.231 184.311L316.662 184.522C315.563 191.061 313.123 197.705 308.366 202.346C303.849 206.775 297.299 209.653 288.01 209.653C278.72 209.653 270.981 207.107 265.456 202.195ZM308.366 137.664C305.965 134.898 302.873 132.818 299.407 131.638L299.513 130.312C302.388 130.312 310.474 130.643 315.999 130.643C321.525 130.643 328.044 130.417 330.814 130.312L331.04 131.743C328.929 132.662 327.013 133.976 325.394 135.615C322.519 138.508 318.77 144.806 317.324 153.997H315.819C314.905 147.977 312.323 142.335 308.366 137.71V137.664Z" fill="#C8A677"/>
    <path d="M338.885 206.715C341.567 206.089 344.093 204.92 346.307 203.28C349.063 201.186 350.508 197.253 351.05 194.089C351.607 191.076 352.164 184.462 352.164 167.753C352.164 151.044 352.059 142.139 348.852 137.529C345.645 132.918 341.324 132.225 338.448 131.668L338.553 130.342C342.98 130.342 351.17 130.674 357.479 130.674C363.788 130.674 371.301 130.448 376.269 130.342L376.39 131.668C370.367 132.994 367.356 136.429 366.106 140.708C364.992 144.595 364.887 152.671 364.887 167.286C364.887 188.379 365.323 196.063 368.094 200.824C369.75 203.581 372.294 205.344 376.269 206.685L376.164 208.011C371.647 207.905 363.893 207.679 358.473 207.679C352.27 207.679 342.754 207.905 339.005 208.011L338.885 206.715Z" fill="#C8A677"/>
    <path d="M388.103 206.715C391.64 206.005 394.857 204.184 397.288 201.517C399.501 198.745 400.495 193.984 400.826 188.454C401.052 185.577 401.277 179.414 401.277 175.286V166.532C401.277 150.712 401.052 141.958 397.95 137.529C394.848 133.099 390.422 132.225 387.561 131.668L387.667 130.342C392.093 130.342 400.284 130.674 406.577 130.674C412.871 130.674 422.07 130.448 427.039 130.342L427.159 131.668C419.631 133.325 416.424 136.76 415.204 141.07C414.105 144.957 414 152.596 414 163.218V171.293C414 177.938 414 185.471 414.542 191.889C415.084 198.308 416.755 202.18 419.526 204.395C420.851 205.455 422.393 206.211 424.042 206.61L423.937 207.936C417.523 207.936 411.546 207.815 406.788 207.815C400.6 207.815 392.184 207.936 388.209 208.041L388.103 206.715ZM429.478 207.936L429.372 206.61C434.319 204.87 438.87 202.161 442.757 198.639C446.733 195.062 450.162 190.919 452.935 186.345L454.366 186.571C453.041 194.104 452.047 202.286 450.827 207.815C449.322 207.936 444.97 208.041 442.757 208.041C438.542 208.101 433.904 208.101 429.478 207.996V207.936Z" fill="#C8A677"/>
    <path d="M458.115 206.715C460.046 205.838 461.836 204.68 463.429 203.28C469.181 198.413 473.05 192.989 477.808 184.356L479.133 184.582C478.918 186.009 478.807 187.449 478.802 188.891C478.802 196.199 480.307 200.071 483.319 203.506C484.834 204.952 486.649 206.047 488.633 206.715L488.528 208.041C484.011 208.041 476.242 207.815 471.048 207.815C465.853 207.815 459.319 208.041 458.22 208.041L458.115 206.715ZM509.772 206.715C513.868 205.721 517.963 203.069 518.806 199.302C518.527 196.195 517.781 193.147 516.593 190.262C515.268 186.722 513.386 182.624 511.715 178.872C507.634 178.751 503.087 178.751 497.788 178.751C492.247 178.751 483.62 178.751 479.645 178.872L481.075 176.326C485.171 176.537 493.241 176.536 497.788 176.536C502.335 176.536 506.746 176.537 510.616 176.326C506.294 166.577 501.988 157.176 495.123 143.33C497.336 137.905 499.444 132.271 500.663 128.73L502.32 128.609C505.752 136.7 509.17 144.55 512.708 152.415C519.469 167.572 528.201 186.842 533.185 194.812C536.602 200.342 540.818 205.359 545.681 206.655L545.561 208.086C543.574 208.086 534.736 207.86 528.096 207.86C523.669 207.86 515.81 208.086 509.848 208.086L509.772 206.715Z" fill="#C8A677"/>
    <path d="M100.846 321.041C102.891 320.573 104.815 319.68 106.492 318.42C108.5 316.6 109.787 314.119 110.121 311.429C110.542 309.154 110.964 304.091 110.964 291.36C110.964 278.628 110.874 271.773 108.435 268.338C107.496 267.08 106.309 266.029 104.946 265.251C103.583 264.473 102.075 263.985 100.515 263.818L100.59 262.808C103.963 262.808 110.211 263.05 115.014 263.05C119.817 263.05 125.117 262.884 128.911 262.808L129.001 263.818C127.229 264.225 125.602 265.108 124.294 266.371C122.986 267.635 122.047 269.232 121.579 270.99C120.736 274.003 120.66 279.758 120.66 287.849V293.876C120.66 307.105 121.082 312.935 123.265 316.551C124.701 318.757 126.89 320.363 129.423 321.071L129.333 322.081C125.885 322.081 119.892 321.825 115.782 321.825C111.069 321.825 103.812 322.005 100.952 322.081L100.846 321.041ZM138.351 320.951C140.911 320.323 143.211 318.911 144.931 316.913C146.42 314.858 147.32 312.436 147.536 309.907C147.935 305.676 148.075 301.424 147.957 297.176V292.369C143.44 292.279 138.698 292.279 134.301 292.279H124.695V290.591H134.301C138.698 290.591 143.079 290.591 147.957 290.591V283.917C147.957 273.551 147.114 271.186 145.684 268.654C144.229 266.481 142.119 264.828 139.661 263.938L139.752 262.929C143.29 262.929 148.514 263.17 152.896 263.17C157.277 263.17 162.757 263.17 166.371 262.929L166.552 264.029C163.918 264.686 161.593 266.235 159.972 268.413C158.877 270.347 158.243 272.507 158.12 274.726C157.789 278.267 157.699 282.998 157.699 289.235C157.699 300.701 157.789 308.22 158.286 311.082C158.566 313.52 159.468 315.844 160.905 317.832C162.347 319.444 164.259 320.562 166.371 321.026L166.13 322.126C161.989 322.126 156.178 321.87 152.579 321.87C149.282 321.87 142.958 322.051 138.502 322.126L138.351 320.951Z" fill="#C8A677"/>
    <path d="M181.969 321.041C184.665 320.501 187.118 319.112 188.97 317.079C190.641 314.969 191.409 311.338 191.665 307.12C191.831 304.935 191.996 300.204 191.996 297.085V292.791C191.996 286.629 191.906 281.235 191.665 277.182C191.424 273.129 190.988 270.613 189.723 268.669C188.68 267.133 187.216 265.929 185.507 265.204C183.96 264.583 182.349 264.133 180.704 263.863L180.795 262.854C183.565 263.019 193.005 263.019 195.203 263.019C197.808 263.019 200.594 263.019 203.198 262.929L203.289 264.029C202.024 267.313 201.693 274.832 201.693 289.251V294.102C201.693 299.164 201.693 304.89 202.114 309.786C202.536 314.683 203.71 317.455 205.818 319.233C206.848 320.082 208.058 320.685 209.356 320.996L209.281 322.021C204.132 322.021 200.006 321.93 196.212 321.93C191.485 321.93 185.086 322.021 182.059 322.096L181.969 321.041ZM218.541 295.654C216.841 294.167 214.751 293.199 212.518 292.866C210.256 292.527 207.969 292.386 205.683 292.445V290.772C208.162 290.819 210.641 290.703 213.105 290.426C214.886 290.217 216.591 289.581 218.074 288.573C219.842 287.314 221.149 285.509 221.793 283.435L222.877 283.601C222.711 285.785 222.545 289.326 222.545 292.113C222.545 294.901 222.545 297.929 222.636 299.782L221.537 299.873C220.803 298.282 219.788 296.837 218.541 295.609V295.654ZM220.483 270.04C216.636 267.026 212.132 264.961 207.339 264.014V263.004C212.819 262.914 216.704 262.839 219.73 262.839C223.103 262.839 225.752 262.839 227.65 263.08C228.493 268.142 228.252 271.095 229.155 278.779L228.056 278.945C226.081 275.537 223.517 272.506 220.483 269.995V270.04ZM213.482 321.99L213.406 320.966C217.058 319.719 220.433 317.775 223.343 315.241C226.457 312.631 229.083 309.489 231.097 305.959L232.196 306.125C231.097 311.866 230.51 317.847 229.592 321.9C228.402 321.99 225.075 322.066 223.343 322.066C220.152 322.051 216.854 322.051 213.482 321.975V321.99Z" fill="#C8A677"/>
    <path d="M254.796 314.804C251.794 312.08 249.413 308.742 247.813 305.017C246.213 301.292 245.431 297.267 245.521 293.213C245.479 289.018 246.304 284.86 247.944 280.999C249.584 277.139 252.004 273.659 255.052 270.779C260.236 266.014 266.77 262.977 273.752 262.085L273.917 263.019C269.156 265.511 265.077 269.132 262.038 273.566C258.478 278.914 256.629 285.221 256.738 291.646C256.69 298.355 258.686 304.919 262.459 310.464C265.878 315.379 270.455 319.374 275.784 322.096L275.694 322.94C267.983 322.799 260.574 319.915 254.796 314.804ZM289.425 272.12C286.088 268.431 282.16 265.325 277.802 262.929L277.877 262.01C284.419 262.007 290.896 263.298 296.938 265.807C297.781 270.779 297.691 276.263 298.609 283.887L297.525 284.052C295.372 279.729 292.648 275.716 289.425 272.12ZM279.654 321.975C283.356 320.621 286.802 318.65 289.847 316.145C293.426 313.32 296.457 309.863 298.79 305.944L299.874 306.201C299.212 309.214 298.368 315.557 297.601 318.842C291.962 321.231 285.938 322.579 279.819 322.819L279.654 321.975Z" fill="#C8A677"/>
    <path d="M312.702 321.041C314.746 320.57 316.669 319.678 318.348 318.42C320.355 316.599 321.643 314.119 321.976 311.429C322.398 309.154 322.82 304.091 322.82 291.36C322.82 278.628 322.729 271.773 320.29 268.338C319.352 267.08 318.164 266.029 316.802 265.251C315.439 264.473 313.93 263.985 312.371 263.818L312.446 262.808C315.818 262.808 322.052 263.05 326.855 263.05C331.658 263.05 336.972 262.884 340.767 262.808L340.857 263.818C339.085 264.225 337.457 265.108 336.15 266.371C334.842 267.635 333.903 269.232 333.434 270.99C332.591 274.003 332.501 279.758 332.501 287.849V293.876C332.501 307.104 332.937 312.935 335.121 316.551C336.556 318.757 338.745 320.363 341.278 321.071L341.188 322.081C337.74 322.081 331.748 321.825 327.638 321.825C322.91 321.825 315.668 322.005 312.807 322.081L312.702 321.041ZM350.207 320.951C352.765 320.319 355.064 318.908 356.786 316.913C358.275 314.858 359.176 312.436 359.391 309.907C359.784 305.655 359.925 301.384 359.813 297.115V292.309C355.296 292.219 350.538 292.219 346.157 292.219H336.551V290.531H346.157C350.538 290.531 354.919 290.531 359.813 290.531V283.857C359.813 273.491 358.969 271.125 357.539 268.594C356.085 266.42 353.975 264.768 351.517 263.878L351.607 262.869C355.145 262.869 360.37 263.11 364.751 263.11C369.132 263.11 374.613 263.11 378.226 262.869L378.407 263.969C375.773 264.625 373.449 266.175 371.827 268.353C370.732 270.286 370.098 272.447 369.976 274.666C369.629 278.207 369.554 282.938 369.554 289.175C369.554 300.641 369.629 308.159 370.141 311.022C370.423 313.457 371.319 315.78 372.746 317.772C374.193 319.384 376.11 320.502 378.226 320.966L377.985 322.066C373.845 322.066 368.033 321.81 364.435 321.81C361.153 321.81 354.829 321.991 350.372 322.066L350.207 320.951Z" fill="#C8A677"/>
    <path d="M398.507 315.301C395.313 312.575 392.769 309.169 391.063 305.331C389.356 301.493 388.53 297.322 388.645 293.123C388.645 281.235 393.87 272.376 402.392 266.892C406.467 264.357 411.035 262.718 415.792 262.085L415.957 263.019C406.773 267.991 399.862 278.282 399.862 291.435C399.862 304.844 406.186 315.226 417.93 322.051L417.839 322.894C410.748 322.503 403.971 319.84 398.507 315.301ZM421.769 321.975C431.887 316.566 437.864 304.679 437.864 293.047C437.864 277.016 428.936 267.238 419.797 262.914L419.887 261.995C427.133 261.967 434.142 264.575 439.611 269.332C446.1 275.058 449.051 283.073 449.051 291.842C449.209 297.273 447.878 302.643 445.203 307.371C442.527 312.098 438.609 316.003 433.874 318.661C430.234 320.812 426.19 322.191 421.995 322.714L421.769 321.975Z" fill="#C8A677"/>
    <path d="M4.57702 430.094C7.27195 429.541 9.72284 428.149 11.5782 426.116C13.2645 424.022 14.0172 420.391 14.2732 416.172C14.4388 413.973 14.6044 409.257 14.6044 406.138V401.829C14.6044 395.682 14.5292 390.273 14.2732 386.235C14.0172 382.197 13.5957 379.651 12.331 377.707C11.2827 376.179 9.82009 374.982 8.11518 374.257C6.56839 373.631 4.95784 373.176 3.31226 372.901L3.40268 371.891C6.17302 372.057 15.6132 372.057 17.8114 372.057C20.4161 372.057 23.2015 372.057 25.8213 372.057L25.8965 373.157C24.6318 376.441 24.3006 383.945 24.3006 398.363V403.17C24.3006 408.232 24.3006 413.973 24.7222 418.854C25.1438 423.736 26.3182 426.538 28.4261 428.301C29.4576 429.151 30.6667 429.759 31.9642 430.079V431.088C26.815 431.088 22.6896 430.998 18.8955 430.998C14.1829 430.998 7.76891 431.088 4.74261 431.179L4.57702 430.094ZM41.1485 404.707C39.4547 403.211 37.3617 402.242 35.126 401.919C32.8691 401.58 30.587 401.439 28.3056 401.497V399.81C30.7793 399.853 33.2532 399.742 35.7132 399.478C37.4953 399.264 39.1996 398.623 40.6817 397.61C42.4575 396.359 43.7661 394.551 44.4007 392.472L45.4846 392.638C45.319 394.838 45.1535 398.379 45.1535 401.166C45.1535 403.953 45.1535 406.982 45.2438 408.835L44.1447 408.925C43.4119 407.348 42.3964 405.918 41.1485 404.707ZM43.0908 379.093C39.2438 376.078 34.7406 374.013 29.9467 373.067L30.037 372.042C35.5024 372.042 39.3869 371.876 42.4132 371.876C45.7858 371.876 48.4357 371.967 50.3479 372.132C51.191 377.195 50.9351 380.148 51.8535 387.817L50.7694 387.983C48.7505 384.583 46.1517 381.564 43.0908 379.063V379.093ZM36.1047 431.043L36.0143 430.034C39.668 428.785 43.0433 426.835 45.9514 424.293C49.0648 421.689 51.6903 418.552 53.7054 415.027L54.8044 415.193C53.7053 420.918 53.1182 426.915 52.1997 430.953C51.0103 431.043 47.6828 431.133 45.9514 431.133C42.7595 431.103 39.4622 431.103 36.1047 431.013V431.043Z" fill="#C8A677"/>
    <path d="M80.6108 384.502C78.0387 381.652 75.2771 378.979 72.345 376.502C70.2857 374.743 67.8236 373.522 65.1782 372.946L65.2686 371.937C67.8733 372.027 71.291 372.102 74.4528 372.102C77.3135 372.102 79.4213 371.937 80.8667 371.937C81.5181 371.929 82.1672 372.015 82.794 372.193C94.598 386.868 110.783 404.496 120.976 415.54L124.093 418.915C124.093 423.555 124.259 428.196 124.515 432.234L122.919 432.49C109.263 416.338 93.002 397.791 80.6108 384.502ZM67.3765 430.139C69.4603 429.336 71.2941 427.995 72.6912 426.252C74.8744 423.63 76.8919 420.014 78.0814 413.099H79.2557C80.2645 420.436 82.7939 424.067 84.8265 426.162C86.8412 428.112 89.3422 429.486 92.0685 430.139L91.9782 431.149C90.2919 431.149 83.0499 430.893 78.5783 430.893C74.7992 430.893 69.5445 431.149 67.4668 431.149L67.3765 430.139ZM117.86 379.696C115.908 376.439 112.809 374.031 109.172 372.946L109.263 371.937C112.274 372.027 118.296 372.193 122.151 372.193C126.005 372.193 131.757 372.027 134.196 371.937V372.946C131.685 373.843 129.492 375.456 127.887 377.587C125.221 381.225 123.559 385.502 123.069 389.987H121.925C121.301 386.296 119.917 382.776 117.86 379.651V379.696Z" fill="#C8A677"/>
    <path d="M164.082 445.779C169.062 441.365 172.317 435.327 173.267 428.738C174.355 419.786 174.833 410.77 174.697 401.753V399.569C174.697 387.681 174.697 380.856 172.167 377.391C171.245 376.117 170.061 375.055 168.695 374.275C167.329 373.496 165.813 373.017 164.248 372.871L164.338 371.861C167.711 371.861 173.944 372.117 178.747 372.117C183.55 372.117 189.286 371.952 193.08 371.861L193.156 372.871C191.323 373.143 189.608 373.941 188.218 375.169C186.829 376.396 185.824 378 185.327 379.786C184.483 382.8 184.393 388.826 184.393 400.036C184.393 406.861 184.649 418.508 184.649 421.792C184.85 426.94 183.558 432.037 180.93 436.467C177.738 441.439 170.827 445.507 164.594 446.758L164.082 445.779Z" fill="#C8A677"/>
    <path d="M196.001 430.094C197.47 429.419 198.834 428.536 200.051 427.472C204.433 423.766 207.384 419.623 211.012 413.053L212.021 413.219C211.854 414.306 211.768 415.404 211.765 416.504C211.765 422.079 212.94 425.032 215.213 427.638C216.37 428.74 217.752 429.578 219.263 430.094V431.104C215.8 431.104 209.898 430.923 205.938 430.923C201.979 430.923 197.01 431.104 196.167 431.104L196.001 430.094ZM235.358 430.094C238.475 429.326 241.591 427.307 242.269 424.429C242.066 422.051 241.497 419.718 240.583 417.513C239.574 414.816 238.144 411.698 236.879 408.835C233.762 408.745 230.299 408.744 226.264 408.744C222.229 408.744 215.469 408.745 212.443 408.835L213.527 406.891C216.658 407.057 222.801 407.057 226.264 407.057C229.727 407.057 233.085 407.057 236.036 406.891C232.753 399.478 229.456 392.307 224.232 381.76C225.918 377.632 227.529 373.323 228.447 370.626H229.712C232.332 376.773 234.937 382.77 237.632 388.706C242.781 400.262 249.436 414.937 253.23 421.009C255.835 425.227 259.042 429.024 262.745 430.049V431.149C261.24 431.149 254.48 430.968 249.421 430.968C246.063 430.968 240.071 431.149 235.524 431.149L235.358 430.094Z" fill="#C8A677"/>
    <path d="M270.981 430.094C273.671 429.54 276.117 428.148 277.967 426.116C279.654 424.022 280.421 420.391 280.662 416.172C280.843 413.973 281.009 409.257 281.009 406.138V399.478C281.009 387.425 280.843 380.751 278.479 377.376C277.541 376.117 276.353 375.066 274.991 374.289C273.628 373.511 272.119 373.023 270.56 372.856L270.635 371.846C274.007 371.846 280.241 372.102 285.044 372.102C289.847 372.102 296.848 371.936 300.642 371.846L300.732 372.856C294.996 374.121 292.557 376.743 291.623 380.027C290.78 383.041 290.69 388.796 290.69 396.902V403.049C290.69 408.112 290.69 413.852 291.111 418.734C291.533 423.615 292.798 426.584 294.905 428.271C295.925 429.076 297.106 429.652 298.368 429.958L298.278 430.968C293.4 430.968 288.838 430.877 285.224 430.877C280.497 430.877 274.098 430.968 271.056 431.058L270.981 430.094ZM302.494 431.013V430.004C306.255 428.687 309.72 426.639 312.687 423.977C315.715 421.254 318.327 418.102 320.441 414.62L321.54 414.786C320.516 420.512 319.763 426.84 318.83 430.968C317.655 431.058 314.313 431.149 312.687 431.149C309.45 431.103 305.911 431.103 302.599 431.013H302.494Z" fill="#C8A677"/>
    <path d="M329.941 430.094C331.98 429.607 333.9 428.716 335.587 427.472C337.598 425.648 338.886 423.162 339.216 420.466C339.637 418.191 340.059 413.129 340.059 400.398C340.059 387.666 339.969 380.811 337.53 377.376C336.59 376.116 335.4 375.064 334.034 374.286C332.669 373.508 331.157 373.021 329.595 372.856L329.685 371.846C333.058 371.846 339.291 372.102 344.094 372.102C348.897 372.102 354.633 371.936 358.428 371.846L358.503 372.856C356.67 373.128 354.955 373.926 353.565 375.153C352.176 376.381 351.171 377.985 350.674 379.771C349.83 382.785 349.74 388.811 349.74 400.021C349.74 416.127 350.086 421.943 352.194 425.634C353.628 427.874 355.855 429.488 358.428 430.154L358.337 431.164C354.889 431.073 348.987 430.908 344.862 430.908C340.134 430.908 332.892 431.073 330.032 431.164L329.941 430.094Z" fill="#C8A677"/>
    <path d="M371.24 429.416C370.322 426.297 369.644 419.291 368.801 412.843L369.885 412.677C371.673 416.463 374.132 419.893 377.142 422.802C380.327 425.924 383.864 428.665 387.682 430.968L387.591 431.992C382.03 432.129 376.491 431.256 371.24 429.416ZM391.642 430.923C396.354 428.06 399.305 424.263 399.305 419.457C399.34 417.742 398.775 416.069 397.709 414.726C395.767 412.12 391.22 409.588 386.839 407.193C382.457 404.797 377.986 402.552 374.944 399.343C373.461 397.895 372.281 396.166 371.472 394.257C370.663 392.348 370.241 390.297 370.232 388.224C370.151 385.828 370.627 383.446 371.622 381.265C372.618 379.085 374.105 377.165 375.968 375.658C379.146 373.218 382.932 371.696 386.914 371.258L387.004 372.358C384.928 373.487 383.197 375.158 381.993 377.193C380.79 379.227 380.159 381.55 380.169 383.915C380.095 385.37 380.335 386.825 380.873 388.179C381.411 389.534 382.233 390.756 383.285 391.764C385.995 394.536 390.452 396.571 394.502 398.589C402.03 402.386 409.257 406.349 409.257 415.163C409.267 417.36 408.797 419.533 407.878 421.53C406.96 423.526 405.617 425.297 403.942 426.719C400.5 429.577 396.265 431.312 391.807 431.691L391.642 430.923ZM400.239 380.314C397.648 377.124 394.544 374.39 391.054 372.223L391.13 371.123C396.29 371.161 401.412 372.015 406.306 373.654C406.969 377.195 407.315 383.945 407.902 389.519L406.803 389.685C404.967 386.305 402.765 383.138 400.239 380.238V380.314Z" fill="#C8A677"/>
    <path d="M430.923 423.841C427.918 421.121 425.534 417.784 423.933 414.058C422.333 410.332 421.553 406.305 421.649 402.251C421.608 398.058 422.432 393.901 424.069 390.041C425.707 386.181 428.122 382.7 431.164 379.816C436.355 375.057 442.894 372.024 449.879 371.138L450.044 372.057C445.287 374.554 441.209 378.174 438.165 382.604C434.606 387.953 432.752 394.258 432.85 400.684C432.799 407.391 434.801 413.952 438.587 419.487C442.003 424.402 446.573 428.401 451.896 431.134L451.821 431.977C444.109 431.845 436.697 428.959 430.923 423.841ZM465.552 381.173C462.22 377.478 458.291 374.371 453.929 371.982L454.004 371.048C460.54 371.053 467.011 372.343 473.05 374.844C473.893 379.816 473.818 385.301 474.737 392.924L473.653 393.09C471.502 388.77 468.777 384.761 465.552 381.173ZM455.781 431.013C459.483 429.665 462.929 427.698 465.974 425.197C469.542 422.362 472.572 418.906 474.917 414.997L476.001 415.238C475.339 418.252 474.496 424.61 473.728 427.894C468.091 430.286 462.065 431.629 455.947 431.857L455.781 431.013Z" fill="#C8A677"/>
    <path d="M497.426 424.353C494.229 421.63 491.684 418.224 489.977 414.386C488.27 410.547 487.446 406.375 487.564 402.175C487.564 390.273 492.789 381.428 501.296 375.944C505.366 373.399 509.936 371.76 514.696 371.138L514.861 372.057C505.677 377.044 498.766 387.335 498.766 400.488C498.766 413.897 505.09 424.263 516.834 431.104L516.758 431.947C509.667 431.555 502.89 428.893 497.426 424.353ZM520.688 431.013C530.791 425.619 536.783 413.732 536.783 402.085C536.783 386.069 527.855 376.276 518.716 371.952L518.806 371.017C526.047 370.993 533.05 373.601 538.515 378.355C545.004 384.095 547.955 392.111 547.955 400.955C548.109 406.383 546.778 411.751 544.106 416.477C541.434 421.204 537.522 425.11 532.793 427.774C529.147 429.922 525.098 431.296 520.899 431.812L520.688 431.013Z" fill="#C8A677"/>
    <path d="M105.047 511.213C102.036 504.81 99.1451 498.226 96.3747 493.344C94.8566 490.76 92.8823 488.473 90.5479 486.594C87.7027 484.197 84.2743 482.596 80.6108 481.954L80.6861 480.944C82.1917 480.944 89.2079 481.2 91.2254 481.2C96.5403 481.11 98.6482 480.944 99.657 480.944C100.172 480.906 100.689 480.994 101.163 481.2C103.767 485.826 107.486 494.354 111.521 503.032L122.061 525.722C120.75 529.702 119.17 533.588 117.333 537.354H116.158C113.9 530.86 110.016 522.091 105.047 511.213ZM81.6196 539.132C83.7758 538.429 85.6977 537.147 87.1753 535.425C90.3284 531.615 92.3475 526.995 93.0021 522.091H94.1765C94.3226 525.912 95.2988 529.655 97.0371 533.06C97.9384 534.603 99.1555 535.937 100.609 536.976C102.062 538.014 103.718 538.734 105.469 539.087L105.393 540.096C102.186 540.096 95.6971 539.84 92.0686 539.84C88.8616 539.84 83.9834 540.096 81.7099 540.096L81.6196 539.132ZM128.986 539.132C130.497 538.942 131.928 538.348 133.128 537.412C134.329 536.476 135.254 535.232 135.807 533.813C137.162 530.8 137.418 525.722 137.418 520.66C137.418 515.598 137.162 507.672 136.906 502.58C136.575 494.399 135.807 487.815 135.807 484.786C135.727 483.763 135.871 482.735 136.228 481.773C136.484 481.095 136.816 480.929 137.734 480.929C139.676 480.929 143.756 481.185 147.505 481.185C150.381 481.185 153.528 481.095 155.847 480.929L155.937 481.939C152.851 482.531 150.109 484.284 148.273 486.835C146.97 489.358 146.357 492.18 146.497 495.016V497.879C146.497 502.942 146.587 508.757 147.009 515.251C147.505 522.784 148.017 530.016 149.192 532.789C151.044 537.173 152.986 538.017 156.449 539.026L156.359 540.126C153.995 540.126 147.084 539.87 143.049 539.87C138.743 539.87 132.419 540.126 129.047 540.126L128.986 539.132Z" fill="#C8A677"/>
    <path d="M170.21 539.132C172.906 538.591 175.359 537.203 177.211 535.169C178.883 533.06 179.65 529.429 179.906 525.21C180.072 523.025 180.238 518.294 180.238 515.176V510.882C180.238 504.719 180.147 499.326 179.906 495.273C179.665 491.22 179.229 488.703 177.964 486.76C176.921 485.223 175.457 484.02 173.748 483.294C172.201 482.674 170.59 482.224 168.945 481.954L169.036 480.944C171.806 481.11 181.246 481.11 183.445 481.11C186.049 481.11 188.835 481.11 191.439 481.02L191.53 482.119C190.265 485.404 189.934 492.922 189.934 507.341V512.147C189.934 517.21 189.934 522.935 190.355 527.832C190.777 532.728 191.951 535.501 194.059 537.279C195.09 538.127 196.3 538.73 197.597 539.041L197.522 540.066C192.373 540.066 188.247 539.975 184.453 539.975C179.726 539.975 173.327 540.066 170.3 540.141L170.21 539.132ZM206.782 513.744C205.082 512.257 202.992 511.29 200.759 510.957C198.497 510.618 196.21 510.477 193.924 510.535V508.863C196.403 508.91 198.882 508.794 201.346 508.516C203.127 508.308 204.832 507.672 206.315 506.663C208.083 505.404 209.39 503.599 210.034 501.525L211.118 501.691C210.952 503.876 210.787 507.416 210.787 510.204C210.787 512.991 210.787 516.019 210.877 517.873L209.778 517.963C209.04 516.388 208.025 514.96 206.782 513.744ZM208.724 488.131C204.877 485.116 200.374 483.052 195.58 482.104L195.655 481.095C201.136 481.004 205.02 480.929 208.046 480.929C211.419 480.929 214.069 480.929 215.966 481.185C216.809 486.233 216.568 489.186 217.471 496.87L216.372 497.035C214.372 493.632 211.782 490.611 208.724 488.116V488.131ZM201.723 540.081L201.647 539.056C205.299 537.81 208.674 535.865 211.585 533.331C214.698 530.722 217.324 527.579 219.338 524.05L220.438 524.216C219.338 529.956 218.751 535.938 217.833 539.99C216.643 540.081 213.316 540.156 211.585 540.156C208.393 540.141 205.095 540.141 201.723 540.066V540.081Z" fill="#C8A677"/>
    <path d="M230.134 539.222C233.477 537.353 236.497 534.957 239.077 532.126C242.083 529 244.842 525.645 247.328 522.091L248.337 522.513C247.412 525.03 246.903 527.682 246.831 530.363C246.709 532.329 247.26 534.277 248.393 535.888C249.526 537.498 251.174 538.674 253.064 539.222L252.974 540.141C250.535 539.976 245.898 539.885 240.673 539.885C236.804 539.885 233.506 540.066 230.314 540.232L230.134 539.222ZM260.894 539.222C263.769 538.619 266.555 537.113 266.72 534.069C266.208 530.785 264.101 526.822 259.388 519.485L244.723 496.629C242.575 492.997 240.122 489.554 237.391 486.338C235.748 484.258 233.511 482.728 230.977 481.954L231.067 480.944C233.506 480.944 238.987 481.11 243.79 481.11C249.18 481.11 255.082 481.019 256.934 480.944L257.024 481.954C254.013 482.963 251.965 484.651 251.544 487.347C251.794 490.034 252.693 492.619 254.163 494.881C255.85 497.894 258.876 502.64 261.692 506.934L272.652 523.372C275.417 527.76 278.577 531.885 282.093 535.696C283.651 537.248 285.518 538.455 287.573 539.237L287.483 540.156C284.472 540.156 280.497 539.9 275.859 539.9C271.478 539.9 264.477 539.991 260.939 540.156L260.894 539.222ZM268.994 498.542C270.008 496.017 270.578 493.336 270.68 490.617C270.81 488.68 270.258 486.758 269.119 485.187C267.98 483.615 266.327 482.492 264.447 482.014V481.004C266.886 481.004 271.975 481.17 277.169 481.17C281.054 481.17 283.839 481.17 287.031 481.004L287.212 482.104C284.186 483.543 281.443 485.513 279.112 487.92C275.688 491.316 272.611 495.046 269.927 499.054L268.994 498.542Z" fill="#C8A677"/>
    <path d="M298.67 539.132C300.709 538.656 302.63 537.769 304.316 536.525C306.321 534.697 307.607 532.213 307.944 529.519C308.366 527.244 308.787 522.182 308.787 509.45C308.787 496.719 308.697 489.864 306.258 486.428C305.318 485.168 304.128 484.116 302.763 483.339C301.397 482.561 299.886 482.074 298.323 481.908L298.414 480.899C301.786 480.899 308.019 481.155 312.822 481.155C317.625 481.155 323.362 480.974 327.156 480.899L327.231 481.908C325.397 482.177 323.68 482.974 322.29 484.202C320.9 485.43 319.896 487.036 319.402 488.824C318.559 491.837 318.468 497.864 318.468 509.059C318.468 525.165 318.815 530.996 320.923 534.672C322.357 536.911 324.583 538.526 327.156 539.192L327.066 540.201C323.618 540.201 317.716 539.945 313.59 539.945C308.863 539.945 301.621 540.126 298.76 540.201L298.67 539.132Z" fill="#C8A677"/>
    <path d="M348.476 532.894C345.476 530.17 343.097 526.832 341.499 523.107C339.902 519.381 339.123 515.356 339.216 511.304C339.175 507.11 339.998 502.953 341.635 499.093C343.273 495.233 345.689 491.752 348.731 488.869C353.921 484.105 360.46 481.067 367.446 480.176L367.612 481.11C362.851 483.601 358.772 487.223 355.733 491.657C352.167 497.003 350.313 503.31 350.418 509.737C350.369 516.443 352.371 523.004 356.154 528.54C359.567 533.454 364.139 537.449 369.464 540.171L369.389 541.015C361.674 540.885 354.259 538.005 348.476 532.894ZM383.105 490.21C379.762 486.521 375.829 483.415 371.466 481.02L371.557 480.1C378.093 480.106 384.564 481.396 390.603 483.897C391.446 488.869 391.356 494.353 392.289 501.977L391.19 502.143C389.052 497.818 386.337 493.804 383.12 490.21H383.105ZM373.333 540.066C377.036 538.712 380.482 536.74 383.526 534.235C387.097 531.406 390.122 527.949 392.455 524.035L393.554 524.291C392.876 527.304 392.048 533.648 391.28 536.932C385.641 539.32 379.617 540.668 373.499 540.91L373.333 540.066Z" fill="#C8A677"/>
    <path d="M418.351 533.391C415.157 530.666 412.613 527.259 410.907 523.422C409.2 519.584 408.374 515.412 408.489 511.213C408.489 499.326 413.729 490.466 422.236 484.982C426.311 482.447 430.879 480.809 435.636 480.176L435.801 481.11C426.617 486.082 419.706 496.372 419.706 509.526C419.706 522.935 426.03 533.316 437.774 540.141L437.683 540.985C430.591 540.6 423.811 537.937 418.351 533.391ZM441.613 540.066C451.731 534.657 457.708 522.769 457.708 511.138C457.708 495.107 448.78 485.329 439.641 481.005L439.716 480.085C446.962 480.055 453.973 482.663 459.44 487.423C465.929 493.148 468.88 501.164 468.88 509.933C469.038 515.363 467.707 520.734 465.031 525.461C462.356 530.189 458.438 534.093 453.703 536.751C450.064 538.905 446.02 540.284 441.824 540.804L441.613 540.066Z" fill="#C8A677"/>
    <path d="M520.989 275.751C519.152 274.485 518.565 274.244 517.27 274.244C515.975 274.244 515.388 274.741 513.386 275.886C505.156 281.345 496.541 286.199 487.61 290.411V291.571C496.635 295.599 505.264 300.461 513.386 306.095C515.388 307.24 515.825 307.737 517.27 307.737C517.96 307.752 518.645 307.627 519.285 307.368C519.924 307.108 520.504 306.722 520.989 306.231C528.724 300.309 537.184 295.403 546.163 291.631V290.471C537.395 286.24 528.977 281.318 520.989 275.751Z" fill="#C8A677"/>
    <path d="M36.9028 275.751C35.0509 274.485 34.4787 274.244 33.1839 274.244C31.8891 274.244 31.3018 274.741 29.2994 275.886C21.0677 281.342 12.4533 286.196 3.52319 290.411V291.571C12.5467 295.602 21.176 300.464 29.2994 306.095C31.3018 307.24 31.7385 307.737 33.1839 307.737C33.8736 307.752 34.559 307.627 35.1984 307.368C35.8379 307.108 36.4179 306.722 36.9028 306.231C44.633 300.303 53.0938 295.396 62.0767 291.631V290.471C53.3085 286.24 44.8907 281.318 36.9028 275.751Z" fill="#C8A677"/>
    <defs>
    <clipPath id="clip0">
    <rect width="548" height="541" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
);

export default Stencil_Type;
import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./story.module.scss";
import cx from "classnames";
import PortableText from "../components/portableText";
import { PageLink } from "../components/link";
import { StoryVideo } from "./storyVideo";

export const Story = ({ data }) => {
  {
    /* <SanityImage
            className={cx(styles.story__secondSectionImage, "z-0 object-cover")}
                        // alt={data.alt}
            asset={data.image.asset}
        /> */
  }

  return (
    <section
      className={cx(
        styles.story__content,
        "pb-20 pt-16 md:pt-0 flex flex-col justify-center items-center w-full h-full relative overflow-hidden"
      )}
    >
      <div className="flex justify-center w-full relative">
        <div
          className={cx(
            styles.story__heading,
            "font-historical text-base md:text-2xl text-center md:w-2/3 pb-10 md:pb-32 px-11 md:pt-20 md:pt-0 text-darkGray"
          )}
        >
          {data.heading}
        </div>
      </div>

      {data.storyBlocks.map((block, key) => {
        return block.fullImage ? (
          <div key={block._key} className="w-full flex flex-col justify-center relative md:mt-20 mb-20">
            <SanityImage
              className={cx(styles.largeImage, "w-full object-cover")}
              alt={block.fullImage.alt}
              asset={block.fullImage.asset}
              gatsbyImageDataArgs={{ width: "100vw" }}
            />
            <div
              className={cx(
                styles.story__fullImageContent,
                "md:ml-0 mt-4 md:mt-0 relative md:absolute w-full flex justify-start md:justify-center items-center md:left-0 pt-1/3 z-10"
              )}
            >
              <div
                className={cx(
                  "md:hidden text-gold font-sans lowercase text-xs md:text-sm pl-8"
                )}
              >
                {block.fullImageHeading}
              </div>
            </div>
            <div
              className={cx(
                styles.story__fullImageContent,
                "absolute w-1/2 flex justify-start right-0 z-10"
              )}
            >
              <div
                className={cx(
                  "hidden md:block text-gold font-sans lowercase text-xs md:text-sm pl-8"
                )}
              >
                {block.fullImageHeading}
              </div>
            </div>
          </div>
        ) : block.centeredHeading ? (
          <div key={block._key} className="w-full flex flex-col items-center relative h-56">
            <div className="flex w-full h-1/2 md:pb-4 relative">
              <div className="ml-10 md:ml-0 w-full border-l border-dashed border-gold md:w-1/2 md:border-0" />
              <div className="hidden md:flex absolute w-full justify-center bottom-0">
                <div
                  className={cx("w-3 h-3 mb-3 bg-gold transform rotate-45")}
                />
              </div>
            </div>
            <div className="flex justify-start items-center md:justify-center w-full -mt-4 md:mt-0">
              <div
                className={cx(
                  "md:hidden ml-9 w-3 h-3 md:mb-3 bg-gold transform rotate-45"
                )}
              />
              <div className="text-gold font-sans lowercase text-xs md:text-sm pl-8 md:pl-0">
                {block.centeredHeading}
              </div>
            </div>
          </div>
        ) : (
          <div
            key={block._key}
            className={cx(
              block.alignment === "right"
                ? "md:flex-row"
                : "md:flex-row-reverse",
              "flex flex-col w-full justify-center relative mb-10"
            )}
          >
            <div
              className={cx(
                block.alignment === "right" ? "" : "",
                " md:w-1/2 md:mb-0 flex flex-col justify-between pl-11 md:px-11 "
              )}
            >
              <div className="flex pr-10 md:pr-0">
                <div className="flex flex-col">
                  <div>
                    <PortableText blocks={block.text} />
                  </div>
                </div>
              </div>
              {block.image && (
                <div>
                  <SanityImage
                    className={cx(
                      block.alignment === "right"
                        ? "-top-40 right-0 transform-gpu rotate-12"
                        : "top-0 left-0 transform-gpu -rotate-12 mt-20 md:mt-0",
                      "w-full md:w-80 md:absolute mt-20 md:mt-0 mb-20 object-cover"
                    )}
                    alt={block.image.alt}
                    asset={block.image.asset}
                    gatsbyImageDataArgs={{ width: "100vw" }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}

      <StoryVideo data={data} />
    </section>
  );
};

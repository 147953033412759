import React, { useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./homeJournal.module.scss";
import cx from "classnames";
import { PageLink } from "../components/link";
// import Arrow from "../icons/arrow";
import { PostCard } from "../components/postCard";

// const sliderStyling = `.testTest .slick-list .slick-track .slick-slide {
//     width: 60vw !important;
// }

// .testTest .slick-list .slick-track {
//     margin-left: 0 !important;
// }`

export const HomeJournal = ({ data }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  return (
    <section
      className={cx(styles.homeJournal__container, "container pb-10 md:py-10")}
    >
      {/* <style>{sliderStyling}</style> */}
      <div className="hidden md:block">
        <div className="flex justify-between items-center w-full">
          <div className="flex font-serif lowercase text-xl text-darkGray">
            Journal
          </div>
          <div className="flex flex-col hover:opacity-80 transition-opacity duration-200 ease-in-out">
            <PageLink
              className="font-serif lowercase font-semibold text-xs md:text-xs leading-none font-bold text-darkGray"
              link={data.ctaLink[0].link}
              _type={data.ctaLink[0]._type}
            >
              {data.ctaLink[0].title}
            </PageLink>
            <div
              className={cx(styles.home__journalDivider, "w-full bg-darkGray")}
            />
          </div>
        </div>

        <div className="relative pt-6 pb-16 md:pt-11 md:pb-7">
          <div className="grid grid-cols-4 w-full">
            {data.journalArticles.map(post => (
              <PostCard
                key={post.id}
                className="pb-6  h-full"
                post={post}
                layout={post.previewLayout.title}
              />
            ))}
          </div>
          <div className="absolute w-px top-0 right-0 h-full bg-offWhite z-10" />
        </div>
      </div>

      <div className="md:hidden">
        {/* <Slider {...settings}>
                {data.journalArticles.map((post) => (
                        <PostCard
                            key={post.id}
                            className="mb-6 md:mb-16"
                            post={post}
                            layout={post.previewLayout.title}
                        />
                    ))}
            </Slider> */}
        <div className="flex justify-between items-center w-full pb-8 pt-20">
          <div className="flex font-serif lowercase text-base">Journal</div>
          <div className="flex flex-col">
            <PageLink
              className="text-xs"
              link={data.ctaLink[0].link}
              _type={data.ctaLink[0]._type}
            >
              {data.ctaLink[0].title}
            </PageLink>
            <div className="border md:border-2 border-black w-full" />
          </div>
        </div>
        <div className={cx(styles.carousel, "")}>
          {data.journalArticles.map(post => (
            <PostCard
              key={post.id}
              className={cx(styles.postCard, "pb-6 h-full")}
              post={post}
              layout={post.previewLayout.title}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

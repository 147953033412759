import cx from "classnames";

import SanityImage from "gatsby-plugin-sanity-image";
import badgeBlanco from "../../static/images/celaya-labelbadge-05.png";
import badgeReposado from "../../static/images/celaya-labelbadge-06.png";
import badgeAnejo from "../../static/images/celaya-labelbadge-07.png";

import Star from "../icons/star";
import StarEmpty from "../icons/starEmpty";
import Flickity from "react-flickity-component";
import * as pdp from "./pdp.module.scss";
import React, { useState } from "react";
import ThemeContext from "../context/ThemeContext";
import { StoreContext } from "../context/store-context";
import {AddToCart} from "../components/addToCart";

export const PDPHeaderSingle = ({ data }) => {
  const [count, setCount] = useState(1);



  const increaseCount = () => {
    setCount(count + 1);
  };

  const decreaseCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const flickityOptions = {
    pageDots: true,
    prevNextButtons: false,
    accessibility: true,
    draggable: true,
    wrapAround: true,
    groupCells: 1
  };

  return (
    <ThemeContext.Consumer>
      {theme => (
        <>
          <div className="container pt-6 md:pt-20 mt-20 mb-12 md:mb-20">
            <div className="md:hidden">
              <Flickity
                className={cx(
                  pdp.carousel,
                  "carousel mb-50 md:mb-70 lg:mb-90 w-full"
                )}
                options={flickityOptions}
                reloadOnUpdate
              >
                {data.images.map((image, i) => (
                  <div data-index={i} key={i} className={cx(pdp.carousel_cell)}>
                    <SanityImage
                      _key={i}
                      alt={image.alt}
                      asset={image.image.asset}
                    />
                  </div>
                ))}
              </Flickity>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="hidden md:block">
                {data.images.map((image, i) => (
                  <SanityImage
                    data-index={i}
                    key={i}
                    _key={i}
                    className={"image--main mb-3"}
                    alt={image.alt}
                    asset={image.image.asset}
                  />
                ))}
              </div>

              <div className="md:px-16">
                <div className="md:px-4 bg-offWhite sticky top-40">
                  {data.type === "blanco" ? (
                    <img
                      src={badgeBlanco}
                      alt="100 Percent Agave - Blanco"
                      className="absolute top-0 md:right-2 left-2 md:left-auto w-14 md:w-20"
                    />
                  ) : (
                    ""
                  )}
                  {data.type === "reposado" ? (
                    <img
                      src={badgeReposado}
                      alt="Barrel Aged Reposado"
                      className="absolute top-0 md:top-20 md:right-2 left-2 md:left-auto w-14 md:w-20"
                    />
                  ) : (
                    ""
                  )}
                  {data.type === "anejo" ? (
                    <img
                      src={badgeAnejo}
                      alt="Dark - Barrel - Aged - Anejo"
                      className="absolute top-0 md:top-20 left-2 md:left-auto w-14 md:w-20"
                    />
                  ) : (
                    ""
                  )}
                  <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
                    <div className="flex justify-center items-center w-full h-full border border-gold py-4 px-8">
                      <div className="pt-6 pb-6 w-full text-center">
                        <p className="font-historical text-lg md:text-2xl">
                          {data.title}
                        </p>
                        <p className="font-serif text-gold text-md">
                          ${data.price}
                        </p>
                        <div className="flex justify-center items-center mt-4 hidden">
                          <Star height="12" width="12" />
                          <Star height="12" width="12" />
                          <Star height="12" width="12" />
                          <Star height="12" width="12" />
                          <StarEmpty height="12" width="12" />
                          <div
                            className={cx(pdp.pdp__stat_heading, "ml-2 mb-1")}
                          >
                            101 Reviews
                          </div>
                        </div>
                        <div className="mb-4 md:mt-6 pb-6 font-serif text-xs border-b border-gold border-dashed lowercase">
                          <div className="hidden md:block">
                            {data.description
                              ? data.description[0].children[0].text
                              : ""}
                          </div>
                        </div>
                        <div className="mb-6 pb-4 border-b border-gold border-dashed lowercase text-left">
                          <label className={cx(pdp.formControl)}>
                            <input
                              type="radio"
                              name="product"
                              value="single"
                              data-price={data.price}
                              data-title={data.title}
                              data-shopify={data.shopify}
                              data-id="123"
                            />
                            <span className="text-xl leading-none mb-4">
                              Single bottle <sup>${data.price}</sup>
                            </span>
                          </label>
                          {data.bundle && (
                            <label className={cx(pdp.formControl)}>
                              <input
                                type="radio"
                                name="product"
                                value="bundle"
                                data-price={data.bundle}
                                data-title={data.title + " - 3×Bundle"}
                                data-shopify={data.shopifyBundle}
                                data-id="456"
                              />
                              <span className="text-xl leading-none mb-4">
                                3×Bundle <sup>${data.bundle} - Save 10%</sup>
                              </span>
                            </label>
                          )}
                        </div>
                        <div className="mb-6 flex justify-between items-center">
                          <div className="flex">
                            <div
                              onClick={decreaseCount}
                              className={cx(
                                pdp.btn_qty,
                                "mr-2 font-serif text-gold rounded-sm border-gold border-2 hover:opacity-80 select-none"
                              )}
                            >
                              -
                            </div>
                            <div
                              onClick={increaseCount}
                              className={cx(
                                pdp.btn_qty,
                                "font-serif text-gold rounded-sm border-gold border-2 hover:opacity-80 select-none"
                              )}
                            >
                              +
                            </div>
                          </div>
                          <div
                            id="count"
                            data-count={count}
                            className="text-xl leading-none mb-4"
                          >
                            QTY: {count}
                          </div>
                        </div>

                        <AddToCart theme={theme} count={count} />

                        <div className="text-left font-serif text-3xs text-gold mt-8">
                          <span className="uppercase">We Ship to:</span>{" "}
                          <span className="lowercase">
                            AK, AZ, CA, CO, CT, DE, FL, GA, ID, IL, IN, KS, KY, LA, MD, MO, NE, NV, NH, NJ, NM, NY, NC, ND, OH, OR, PA, RI, TX, VA, WA, WI
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:hidden text-center">
            <div className="px-10 pb-12 font-serif text-xs border-b border-gold border-dashed lowercase">
              {data.description ? data.description[0].children[0].text : ""}
            </div>

            <div className="border-b border-gold border-dashed pt-10 pb-12">
              <div
                className={cx(pdp.pdp__stat_heading, "text-gold text-md mb-1")}
              >
                Tasting Notes
              </div>
              <div
                className={cx(pdp.pdp__stat)}
                dangerouslySetInnerHTML={{ __html: data.notes }}
              />
            </div>
          </div>

          {data.type !== "bundle" ? (
            <div className="grid grid-cols-2 md:grid-cols-3 md:mb-20">
              <div className="hidden md:block text-center border-r border-dashed border-gold pt-10 pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-1"
                  )}
                >
                  Tasting Notes
                </div>
                <div
                  className={cx(pdp.pdp__stat)}
                  dangerouslySetInnerHTML={{ __html: data.notes }}
                />
              </div>
              <div className="text-center border-r border-dashed border-gold pt-10 pb-14 md:pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-2 md:mb-1"
                  )}
                >
                  Agave
                </div>
                <div className={cx(pdp.pdp__stat_lg)}>{data.agave}</div>
              </div>
              <div className="text-center pt-10 pb-14 md:pb-10">
                <div
                  className={cx(
                    pdp.pdp__stat_heading,
                    "text-gold text-md mb-2 md:mb-1"
                  )}
                >
                  {data.type === "blanco" ? "Agave-Aged" : "Barrel-Aged"}
                </div>
                <div className={cx(pdp.pdp__stat_lg)}>{data.barrel}</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </ThemeContext.Consumer>
  );
};

import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./storySecondSection.module.scss";
import cx from "classnames";

export const StorySecondSection = ({ data }) => {

  
  return (
    <section
    className="pb-8 flex flex-col justify-center items-center w-full h-full relative"
    >
        <div className="absolute top-0 flex flex-col w-full h-full items-center z-10">
            <div className="absolute top-0 left-0 w-1/2 h-full border-r border-dashed border-gold z-10 "></div>
            <div className="w-1/2"></div>
            <div className="absolute flex justify-center items-center w-32 h-12 bg-gold text-black top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 font-historical">{data.description}</div>
        </div>
        <SanityImage
            className={cx(styles.story__secondSectionImage, "z-0 object-cover")}
            alt={"The Tequila Process Continues"}
            asset={data.image.asset}
        />
    </section>
  );
};
import React, { useState, useEffect } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as styles from "./faq.module.scss";
import cx from "classnames";
import closestIndexTo from "date-fns/closestIndexTo/index";
// import Stencil_Type from "../icons/stencil_type";
import C_Logo_Inverted from "../icons/c_logo-inverted";
import { PageLink } from "../components/link";

export const Faq = ({ data }) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const expand = (e) => {
        e.preventDefault();
        let panel = e.target.parentNode.children[1]
        panel.classList.toggle(styles.active)
    }


  return (
    <section
    className={cx(styles.faq_container, "flex flex-col items-center pt-40 md:pt-64 relative" )}
    >
        {/* <div className={cx(scrollPosition > 120 ? "bg-opacity-1" : "bg-opacity-0" , styles.sectionHeader, "section_header fixed top-0 left-0 right-0 w-full flex justify-center items-center w-full justify-center items-center bg-offWhite md:h-28 z-10 px-4 md:px-0")}>
                <div className={cx(styles.sectionHeaderInner, "flex justify-center items-center h-full border-b border-dashed border-gold pt-6 md:pt-0 pb-4 md:pb-0")}>
                    <C_Logo_Inverted className={cx(styles.sectionHeaderLogo)} />
                </div>

            </div>
 */}



        <div className="px-4 md:px-0 md:flex md:flex-col md:items-center">
            {data.sections.map((section) => (
                <div id={section.title} className="flex flex-col items-start w-full md:w-1/2 py-8">
                      <div  className="font-historical text-lg border-b border-dashed border-gold w-full                      pb-2">{section.title}</div>
                      {section.questions.map((question) => (
                          <div className={cx(styles.a_container, "w-full text-black border-b border-dashed                    border-gold py-4")}>
                              <p  onClick={expand} className={cx(styles.a_btn, "font-serif lowercase                      text-xs cursor-pointer")}>{question.title}<span></span></p>
                              <div className={cx(styles.a_panel, "font-historical text-xs")}>{question.                   answer}</div>
                          </div>
                      ))}
                </div>
            ))}
        </div>

        <div className="hidden absolute top-64 left-10 md:flex flex-col text-gold font-serif lower-case text-xs pt-8">
            <ul>
                {data.sections.map((section) => (
                    <li className="font-serif lowercase pb-4">
                        <a href={`/faq#${section.title}`}>
                        {section.title}
                        </a>
                    </li>
                ))}
            </ul>

        </div>
        <div className="flex flex-col justify-center items-center w-full bg-gold py-20 mb-48 mt-40">
            <div className="text-black font-historical text-lg md:text-xl w-full md:w-1/3 text-center">{data.contactHeading}</div>
            <PageLink
                className="font-serif lowercase bg-black text-white w-auto py-4 px-4 mt-10 text-xs rounded-sm"
                link={data.contactLink[0].link}
                _type={data.contactLink[0]._type}
            >
                {data.contactLink[0].title}
            </PageLink>
        </div>
    </section>
  );
};

import React, { useEffect, useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as styles from "./hero.module.scss";
import cx from "classnames";
import Stencil_Type from "../icons/stencil_type";
import C_Logo_Inverted from "../icons/c_logo-inverted";
import { ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";

export const Hero = ({ data }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerLogoLocked, setHeaderLogoLocked] = useState(false);
  const [headerLogoPositioned, setHeaderLogoPositioned] = useState(null);

  const handleScroll = () => {
    const parentEl = document.getElementById("heroSection");

    const viewportEl = parentEl.querySelector(".cLogoInvert");
    const distanceFromTop = viewportEl.getBoundingClientRect().top;
    if (distanceFromTop <= 24) {
      setHeaderLogoLocked(true);
    } else {
      setHeaderLogoLocked(false);
    }

    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    const parentEl = document.getElementById("heroSection");

    const viewportEl = parentEl.querySelector(".cLogoInvert");
    const distanceFromTop = viewportEl.getBoundingClientRect().top;
    if (distanceFromTop <= 14) {
      setHeaderLogoLocked(true);
    } else {
      setHeaderLogoLocked(false);
    }

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      id="heroSection"
      className={cx(styles.hero_container, "block bg-black z-20")}
    >
      <ParallaxProvider>
        <div
          className={cx(
            styles.hero_imageContainer,
            "relative w-full h-full flex flex-col items-center justify-start"
          )}
        >
          {/* {data.image && (
            <SanityImage
              className="absolute w-full h-full top-0 object-cover"
              alt={data.alt}
              asset={data.image.asset}
            />
          )} */}
          {data.file?.asset.mimeType.startsWith("video/") ? (
            <video
              className={cx(
                styles.hero__backgroundVideo,
                "h-full w-full object-cover z-0"
              )}
              onLoadedMetadata={event => (event.target.muted = true)}
              autoPlay={true}
              playsInline={true}
              muted={true}
              loop={true}
              preload="none"
              onContextMenu={event => (event.preventDefault())}
            >
              <source
                src={data.file.asset.url}
                type={data.file.asset.mimeType}
              />
            </video>
          ) : null}
          <div className="z-20">
            <Parallax y={[-0, 17]}>
              <div
                className={cx(
                  styles.hero_logoContainer,
                  "flex flex-col items-center z-20"
                )}
              >
                <Stencil_Type className={cx(styles.hero_stencil, "z-10")} />
                <C_Logo_Inverted
                  className={cx(
                    styles.hero_c_logo,
                    "cLogoInvert z-10 mt-12 mb-10",
                    headerLogoLocked ? "opacity-0" : "opacity-1"
                  )}
                />
              </div>
            </Parallax>
          </div>
        </div>
      </ParallaxProvider>

      <C_Logo_Inverted
        className={cx(
          styles.hero_c_logo_locked,
          "cLogoInvert hidden z-10 mt-12 mb-10",
          headerLogoLocked ? "md:flex" : "hidden"
        )}
      />
      <C_Logo_Inverted
        className={cx(
          styles.hero_c_logo_locked_mobile,
          "cLogoInvert z-10 mt-12 mb-10",
          headerLogoLocked ? "flex md:hidden" : "hidden"
        )}
      />
    </section>
  );
};

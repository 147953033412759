import React from "react";
import { Sections } from "../sections/sections";

export const renderSections = (sections, location) => {

  if (sections) {
    return sections.map((section) => (
      <Sections key={section._key} reactSection={section} location={location} />
    ));
  }
};
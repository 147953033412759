import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./storyThirdSection.module.scss";
import cx from "classnames";
import IllustrationTwo from "../icons/illustrationTwo";

export const StoryThirdSection = ({ data }) => {

  
  return (
    <section
    className="md:container pb-8 flex flex-col justify-center items-center w-full h-full relative"
    >
        <div className="md:hidden grid grid-cols-2 w-full h-32">
            <div className="grid-cols-1 border-r border-dashed border-gold"></div>
            <div className="grid-cols-1"></div>
        </div>
       
        <div className="grid grid-cols-1 md:grid-cols-2 w-full">

            <div className="col-span-1 border-0 md:border-r border-dashed border-gold flex flex-col justify-between items-start relative">
                <div className="flex flex-col md:pb-48 w-full md:w-3/4 px-4 md:px-0">
                    <div className="text-gold text-xs font-serif lowercase">{data.subheading1}</div>
                    <div className="font-historical text-black text-sm md:text-lg">{data.text1}</div>
                </div>
                <div className="md:hidden grid grid-cols-2 w-full h-32">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>
                <div className="relative">
                    <SanityImage
                        className={cx(styles.story__imageOne, "pb-8")}
                        alt={"A Legend With Humble Origins"}
                        asset={data.image1.asset}
                    />
                    <IllustrationTwo className="absolute -top-6 -right-6"/>
                </div>
                <div className="md:hidden grid grid-cols-2 w-full h-32">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>
                <div className={cx(styles.story__description, "relative md:absolute flex justify-center items-center")}><div className="relative md:absolute flex justify-center items-center md:top-1/2 md:left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2  text-black bg-gold w-32 h-12 font-historical">{data.description}</div></div>
                <div className="md:hidden grid grid-cols-2 w-full h-32">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>
            </div>

            <div className="col-span-1 flex flex-col items-end justify-center">
                
                <div className="flex flex-col w-full md:w-3/4 px-4 md:px-0">
                    <div className="text-gold text-xs font-serif lowercase">{data.subheading2}</div>
                    <div className="font-historical text-black text-sm md:text-lg">{data.text2}</div>
                </div>
            </div>

            <div className="md:hidden grid grid-cols-2 w-full h-32">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>
            <div className="md:hidden flex justify-end w-full">
                
                <SanityImage
                        className={cx(styles.story__imageTwo)}
                        alt={"Tequila History"}
                        asset={data.image2.asset}
                />
            </div>
            <div className="md:hidden grid grid-cols-2 w-full h-32">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>
            <div className="col-span-1 flex justify-start items-end border-r border-dashed border-gold md:pt-40 md:pb-40">
                <div className="flex flex-col w-full md:w-3/4 px-4 md:px-0">
                    <div className="text-gold text-xs font-serif lowercase">{data.subheading3}</div>
                    <div className="font-historical text-black text-sm md:text-lg">{data.text3}</div>
                </div>
            </div>


            <div className="col-span-1 hidden md:flex justify-center pt-40 pl-10 pb-40">
                
            <SanityImage
                        className={cx(styles.story__imageTwo, "pb-8")}
                        alt={"How Celaya Began Making Tequila"}
                        asset={data.image2.asset}
                />
            </div>

        </div>

        <div className="md:hidden grid grid-cols-2 w-full h-32">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>
        <div className="flex justify-center w-full">
            <div className="flex justify-center items-center text-black bg-gold w-32 h-12 font-historical">{data.description2}</div>
        </div>
    </section>
  );
};
import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./storyFirstSection.module.scss";
import cx from "classnames";
import IllustrationOne from "../icons/illustrationOne";
import C_Logo_Inverted from "../icons/c_logo-inverted";

export const StoryFirstSection = ({ data }) => {

  
  return (
    <section
    className={cx(styles.story__heroContainer, "md:container flex flex-col justify-center items-center w-full h-full" )}
    >
        <div className={cx(styles.sectionHeader, "fixed top-0 left-0 right-0 w-full flex justify-center items-center w-full justify-center items-center md:h-28 z-0 bg-none px-4 md:px-0")}>
                <div className={cx(styles.sectionHeaderInner, "flex justify-center items-center h-full border-b border-dashed border-gold pt-6 md:pt-0 pb-4 md:pb-0")}>
                    <C_Logo_Inverted className={cx(styles.sectionHeaderLogo)} />
                </div>
          
            </div>
        <div className="flex flex-col w-full items-center px-4 md:px-0">
            <div className="font-historical text-base md:text-2xl w-full md:w-3/4 py-28 text-center">{data.heading}</div>
            <div className="font-historical flex justify-center items-center w-32 h-12 bg-gold text-black text-xs">{data.description}</div>
        </div>

        <div className="md:hidden grid grid-cols-2 w-full h-20">
            <div className="grid-cols-1 border-r border-dashed border-gold"></div>
            <div className="grid-cols-1"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 w-full">

            <div className="col-span-1 border-0 md:border-r border-dashed border-gold flex flex-col justify-between items-start pt-4">
                <div className="flex flex-col md:pb-48 px-4 md:px-0">
                    <div className="text-gold text-xs font-serif lowercase">{data.subheading1}</div>
                    <div className="font-historical text-black text-sm md:text-lg">{data.text1}</div>
                </div>
                <SanityImage
                        className={cx(styles.story__imageTwo, "hidden md:block pb-8")}
                        alt={"The Story of Celaya Tequila"}
                        asset={data.image2.asset}
                />
                 <div className="md:hidden grid grid-cols-2 w-full h-20">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>
                <div className="md:hidden w-full flex justify-end">
                <SanityImage
                        className={cx(styles.story__imageOne)}
                        alt={"Tequila History"}
                        asset={data.image1.asset}
                />
                </div>
                
            </div>

            <div className="md:hidden grid grid-cols-2 w-full h-20">
                <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                <div className="grid-cols-1"></div>
            </div>

            <div className="col-span-1 flex flex-col items-end justify-between pt-4">
                <SanityImage
                        className={cx(styles.story__imageOne, "hidden md:block")}
                        alt={"How Celaya Tequila Began"}
                        asset={data.image1.asset}
                />
                <div className="flex flex-col w-full md:w-3/4 px-4 md:px-0 pb-8 md:pb-0">
                    <div className="text-gold text-xs font-serif lowercase">{data.subheading2}</div>
                    <div className="font-historical text-black text-sm md:text-lg">{data.text2}</div>
                    <div className="hidden md:flex justify-end w-full pt-8 pr-24 pb-8">
                        <IllustrationOne />
                    </div>
                </div>

                <div className="md:hidden grid grid-cols-2 w-full h-28">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>


                <div className="md:hidden flex justify-start w-full relative">
                    <SanityImage
                        className={cx(styles.story__imageTwo)}
                        alt={"Celaya Tequila — A History"}
                        asset={data.image2.asset}
                    />
                    <div className="md:hidden w-full flex justify-center relative">
                        <IllustrationOne className="absolute -top-20 right-10"/>
                    </div>
                   
                </div>
                <div className="md:hidden grid grid-cols-2 w-full h-32">
                    <div className="grid-cols-1 border-r border-dashed border-gold"></div>
                    <div className="grid-cols-1"></div>
                </div>
            </div>

        </div>
    </section>
  );
};
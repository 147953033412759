import React, { useEffect } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import PortableText from "../components/portableText";
import * as styles from "./homeClip.module.scss";
import cx from "classnames";

export const HomeClip = ({ data }) => {
  useEffect(() => {}, []);

  return (
    <section
      className={cx(
        styles.home__clipContainer,
        "pb-8 relative flex justify-center items-center w-full h-full"
      )}
    >
      {data.file?.asset.mimeType.startsWith("video/") ? (
        <video
          className="top-0 left-0 h-full w-full object-cover bg-black absolute z-0"
          // onLoadedMetadata={event => (event.target.muted = true)}
          autoPlay={true}
          playsInline={true}
          muted
          loop={true}
          preload="true"
          // poster={data.image.asset.url}
        >
          <source src={data.file.asset.url} type={data.file.asset.mimeType} />
        </video>
      ) : (
        data.image?.asset && (
          <SanityImage
            className="top-0 left-0 h-full w-full object-cover bg-black absolute z-0"
            alt={data.title1}
            asset={data.image.asset}
            width={3840}
            height={2160}
          />
        )
      )}
    </section>
  );
};

import React, { useEffect } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./storyHero.module.scss";
import cx from "classnames";

export const StoryVideo = ({ data }) => {
  useEffect(() => {}, []);

  return (
    <section
      className={cx(
        styles.story__heroContainer,
        "container pb-8 relative flex justify-center items-center w-full h-full md:mt-24"
      )}
    >
      <div className="flex flex-col items-center w-full">
        <div className="heading leading-none font-serif lowercase text-lg md:text-3xl w-3/5 md:w-1/2 text-center z-10 text-white transition-opacity duration-500 ease-in-out">
          {data.ctaHeading}
        </div>
      </div>

      {data.file?.asset.mimeType.startsWith("video/") ? (
        <div>
          <video
            className="top-0 left-0 h-full w-full object-cover bg-black absolute -z-10"
            // onLoadedMetadata={event => (event.target.muted = true)}
            autoPlay={true}
            playsInline={true}
            muted
            loop={true}
            preload="true"
            poster={data.ctaImage.asset.url}
          >
            <source src={data.file.asset.url} type={data.file.asset.mimeType} />
          </video>
        </div>
      ) : (
        data.image?.asset && (
          <SanityImage
            className={cx(
              styles.story__heroImage,
              "top-0 left-0 h-full w-full object-cover bg-black absolute z-0"
            )}
            alt={data.ctaImage.alt}
            asset={data.ctaImage.asset}
            width={3840}
            height={2160}
          />
        )
      )}
    </section>
  );
};

import React, { useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./homeProductList.module.scss";
import cx from "classnames";
import { PageLink } from "../components/link";
import Arrow from "../icons/arrow";

export const HomeProductList = ({ data }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <section className="pt-10 pb-14 md:py-10 bg-beige">
      <div className="grid grid-cols-1 lg:grid-cols-2 w-full relative">
        <div
          className={cx(
            styles.home__productListItems,
            "col-span-1 flex flex-col"
          )}
        >
          {data.products.map((product, index) => (
            <PageLink link={product.link[0].link} _type={product.link[0]._type}>
              <div
                className={cx(
                  styles.home__productListItem,
                  index === 0 ? "border-t" : "",
                  "flex justify-between w-full border-b border-dashed border-gold relative text-darkGray"
                )}
              >
                {index === selectedItem ? (
                  <div className="flex justify-between items-center w-full  px-6">
                    <div
                      onMouseEnter={() => {
                        setSelectedItem(index);
                      }}
                      className="flex justify-between items-center w-full"
                    >
                      <div className="flex items-center font-sans">
                        <div className="flex items-center font-historicalItalic text-sm md:text-lg z-10 pr-4">
                          {product.title}{" "}
                          <span
                            className={cx(
                              product.annotation
                                ? "ml-2 mb-6 whitespace-nowrap"
                                : "hidden"
                            )}
                          >
                            <div className="text-2xs md:text-xs flex items-center font-sans text-gold leading-none border-t md:border-t-2 border-b md:border-b-2 border-gold h-4 md:h-5 pb-1.5">
                              {product.annotation}
                            </div>
                          </span>
                        </div>
                        <Arrow className="hidden md:flex z-10" />
                      </div>
                      <div
                        className={cx(
                          styles.home__productPrice,
                          "font-serif z-10"
                        )}
                      >
                        {product.price}
                      </div>
                    </div>
                    <SanityImage
                      className="hidden md:flex absolute top-0 left-0 h-full w-full object-cover object-center z-0"
                      alt={data.backgroundImage.alt}
                      asset={data.backgroundImage.asset}
                    />
                  </div>
                ) : (
                  <div
                    onMouseEnter={() => {
                      setSelectedItem(index);
                    }}
                    className="flex justify-between items-center w-full px-6 font-sans"
                  >
                    <div className="flex items-center font-historical text-sm md:text-lg z-10">
                      {product.title}{" "}
                      <span
                        className={cx(
                          product.annotation
                            ? "ml-2 mb-6 whitespace-nowrap"
                            : "hidden"
                        )}
                      >
                        <div className="text-2xs md:text-xs flex items-center font-sans text-gold leading-none border-t md:border-t-2 border-b md:border-b-2 border-gold h-4 md:h-5 pb-1.5">
                          {product.annotation}
                        </div>
                      </span>
                    </div>
                    <div
                      className={cx(
                        styles.home__productPrice,
                        "font-serif z-10"
                      )}
                    >
                      {product.price}
                    </div>
                  </div>
                )}
              </div>
            </PageLink>
          ))}
          <div className="flex justify-start w-full pt-8">
            <div className="flex justify-center w-full">
              <div className="flex flex-col hover:opacity-80 transition-opacity duration-200 ease-in-out">
                <PageLink
                  className="font-serif lowercase font-semibold text-xs md:text-xs leading-none font-bold text-darkGray"
                  link={data.ctaLink[0].link}
                  _type={data.ctaLink[0]._type}
                >
                  {data.ctaLink[0].title}
                </PageLink>
                <div
                  className={cx(
                    styles.home__productListDivider,
                    "w-full bg-darkGray"
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex col-span-1 w-full h-full justify-center items-center h-auto pl-8 pr-8">
          <div className="flex justify-center items-center w-full h-full border-2 border-gold p-1">
            <div className="flex justify-center items-center w-full h-full border border-gold p-4">
              {data.products.map((product, index) => {
                if (index === selectedItem && product.image) {
                  return (
                    <PageLink
                      className="flex justify-center items-center w-full h-full"
                      link={product.link[0].link}
                      _type={product.link[0]._type}
                    >
                      <SanityImage
                        className={cx(styles.home__productListImage)}
                        alt={product.alt}
                        asset={product.image.asset}
                      />
                    </PageLink>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-start w-full pt-8">
        <div className="flex justify-center w-1/2">
            <div class="flex flex-col">
                <PageLink
                    className="text-xs md:text-sm"
                    link={data.ctaLink[0].link}
                    _type={data.ctaLink[0]._type}
                >
                    {data.ctaLink[0].title}
                </PageLink>
                <div className="border md:border-2 border-black w-full"></div>
            </div>
        </div>

    </div> */}
    </section>
  );
};

import React from "react";
import { Hero } from "./hero";
import { HomeHistory } from "./homeHistory";
import { HomeProducts } from "./homeProducts";
import { HomeClip } from "./homeClip";
import { HomeProductList } from "./homeProductList";
import { HomeJournal } from "./homeJournal";
import { StoryHero } from "./storyHero";
import { Story } from "./story";
import { StoryFirstSection } from "./storyFirstSection";
import { StorySecondSection } from "./storySecondSection";
import { StoryThirdSection } from "./storyThirdSection";
import { StoryFourthSection } from "./storyFourthSection";
import { Faq } from "./faq";
import { Contact } from "./contact";
import { Legal } from "./legal";
import { PLP } from "./plp";
import { PDP } from "./pdp";
import { StoreLocater } from "./store-locater";

export const Sections = ({ reactSection }) => {
  switch (reactSection._type) {
    case "hero":
      return <Hero data={reactSection} />;
    case "homeHistory":
      return <HomeHistory data={reactSection} />;
    case "homeProducts":
      return <HomeProducts data={reactSection} />;
    case "homeClip":
      return <HomeClip data={reactSection} />;
    case "homeProductList":
      return <HomeProductList data={reactSection} />;
    case "homeJournal":
      return <HomeJournal data={reactSection} />;
    case "storyHero":
      return <StoryHero data={reactSection} />;
    case "story":
      return <Story data={reactSection} />;
    case "storyFirstSection":
      return <StoryFirstSection data={reactSection} />;
    case "storySecondSection":
      return <StorySecondSection data={reactSection} />;
    case "storyThirdSection":
      return <StoryThirdSection data={reactSection} />;
    case "storyFourthSection":
      return <StoryFourthSection data={reactSection} />;
    case "faq":
      return <Faq data={reactSection} />;
    case "contact":
      return <Contact data={reactSection} />;
    case "legal":
      return <Legal data={reactSection} />;
    case "plp":
      return <PLP data={reactSection} />;
    case "pdp":
      return <PDP data={reactSection} />;
    case "storeLocater":
      return <StoreLocater data={reactSection} />;
    default:
      return (
        <section className="text-center my-5">
          <b>Section &quot;{reactSection._type}&quot; not implemented.</b>
        </section>
      );
  }
};

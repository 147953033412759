import React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
// import PortableText from "../components/portableText";
import * as styles from "./storyFourthSection.module.scss";
import cx from "classnames";
import { PageLink } from "../components/link";

export const StoryFourthSection = ({ data }) => {

  
  return (
    <section
    className="pb-8 flex flex-col justify-start items-start w-full h-full relative my-20"
    >
       
        <div className="flex flex-col absolute top-0 left-0">
            <div className="font-historical flex justify-start z-20 text-white text-xl w-3/5 pl-6 pt-6">{data.heading}</div>

            <div className="flex flex-col w-max ml-6 pt-8">
                <PageLink
                    className="text-xs text-white z-20 w-max font-serif lowercase"
                    link={data.ctaLink[0].link}
                    _type={data.ctaLink[0]._type}
                >
                    {data.ctaLink[0].title}
                </PageLink>
                <div className="border-2 border-white z-20 w-full"></div>
            </div>
        </div>
        
        <SanityImage
            className={cx(styles.story__fourthSectionImage, "z-0 object-cover")}
                        // alt={data.alt}
            asset={data.image.asset}
        />
    </section>
  );
};
import React, { useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as styles from "./contact.module.scss";
import cx from "classnames";
import { PageLink } from "../components/link";
import C_Logo_Inverted from "../icons/c_logo-inverted";

const onSubmit = async (event, setSubmitText) => {
  event.preventDefault();
  setSubmitText("Submitting ...");
  const formElements = [...event.currentTarget.elements];
  const isValid =
    formElements.filter(elem => elem.name === "bot-field")[0].value === "";

  const validFormElements = isValid ? formElements : [];

  if (validFormElements.length < 1) {
    // or some other cheeky error message
    setSubmitText("It looks like you filled out too many fields!");
  } else {
    const filledOutElements = validFormElements
      .filter(elem => !!elem.value)
      .map(
        element =>
          encodeURIComponent(element.name) +
          "=" +
          encodeURIComponent(element.value)
      )
      .join("&");

    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: filledOutElements
    })
      .then(() => {
        setSubmitText("Submitted");
      })
      .catch(error => {
        setSubmitText("Error");
      });
  }
};

export const Contact = ({ data }) => {
  const [submitText, setSubmitText] = useState(null);
  const [emailValid, setEmailValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const nameChange = event => {
    let nameInput = event.target.value;
    if (nameInput === "" || nameInput === " ") {
      setNameValid(false);
    } else {
      setNameValid(true);
    }
    checkValid();
  };

  const emailChange = event => {
    let emailInput = event.target.value;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(emailInput)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
    checkValid();
  };

  const messageChange = event => {
    let messageInput = event.target.value;
    if (messageInput === "" || messageInput === " ") {
      setMessageValid(false);
    } else {
      setMessageValid(true);
    }
    checkValid();
  };

  const checkValid = () => {
    if (nameValid && emailValid && messageValid) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  return (
    <section
      className={cx(
        styles.contact_container,
        "container flex flex-col items-center pt-20 my-20 relative px-4 md:px-0"
      )}
    >
      {/* <div className={cx(styles.sectionHeader, "fixed top-0 left-0 right-0 w-full flex justify-center items-center w-full justify-center items-center md:h-28 z-0 bg-offWhite px-4 md:px-0")}>
                <div className={cx(styles.sectionHeaderInner, "flex justify-center items-center h-full border-b border-dashed border-gold pt-6 md:pt-0 pb-4 md:pb-0")}>
                    <C_Logo_Inverted className={cx(styles.sectionHeaderLogo)} />
                </div>

            </div> */}

      <div className="w-full md:w-1/2">
        <div className="text-center font-serif text-base lowercase">
          {data.heading}
        </div>
        <div className="text-center font-historical text-sm pt-6">
          {data.subheading}
        </div>
        <form
          id="#contact-form"
          className="flex flex-col pt-28"
          name="contact"
          method="POST"
          data-netlify="true"
          onSubmit={e => onSubmit(e, setSubmitText)}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p className="hidden">
            <label>
              Don’t fill this out if you expect to hear from us!
              <input name="bot-field" value="" readOnly />
            </label>
          </p>
          <label
            className="font-serif lowercase text-gold text-xs pb-1"
            htmlFor="name"
          >
            Your name
          </label>
          <input
            onChange={nameChange}
            id="name"
            className={cx(
              styles.contactInput,
              "font-historicalItalic text-base border border-gold bg-offWhite pl-4 pt-1"
            )}
            type="text"
            name="name"
            placeholder="Your name"
          />
          <label
            className="font-serif lowercase text-gold text-xs pb-1 mt-7"
            htmlFor="email"
          >
            Email address
          </label>
          <input
            onChange={emailChange}
            id="email"
            className={cx(
              styles.contactInput,
              "font-historicalItalic text-base border border-gold bg-offWhite pl-4 pt-1"
            )}
            type="email"
            name="email"
            placeholder="example@email.com"
          />
          <label
            className="font-serif lowercase text-gold text-xs pb-1 mt-7"
            htmlFor="message"
          >
            Your message
          </label>
          <textarea
            onChange={messageChange}
            id="message"
            className={cx(
              styles.contactTextArea,
              "font-historicalItalic text-base border border-gold bg-offWhite pl-4 pt-1"
            )}
            name="message"
            placeholder="Ask a question or send us a message..."
          />
          <div className="flex justify-center w-full pt-10">
            <button
              disabled={submitDisabled ? true : false}
              style={
                submitText === "Error"
                  ? { backgroundColor: "#979797" }
                  : { backgroundColor: "#000000" }
              }
              className="flex justify-center items-center font-serif lowercase text-xs text-white bg-black w-full md:w-7/12 h-14 rounded-sm"
              type="submit"
            >
              {submitText ? <span>{submitText}</span> : <span>Submit</span>}
            </button>
          </div>
          <div className="flex justify-center w-full pt-4">
            <div className="text-2xs mt-2 text-center">
              You agree to the{" "}
              <PageLink link="/privacy-policy" className="text-gold text-2xs">
                Privacy Policy
              </PageLink>{" "}
              and{" "}
              <PageLink link="/terms" className="text-gold text-2xs">
                Terms of Service
              </PageLink>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

import React from "react";

const StarEmpty = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4 2.156 9.72 6.22l.113.346H14.47l-3.458 2.512-.293.213.112.346 1.32 4.064-3.457-2.512-.294-.213-.294.213-3.457 2.512 1.32-4.064.113-.346-.294-.213L2.33 6.566H6.967l.113-.346L8.4 2.156Z"
      stroke="#404040"
    />
  </svg>
);

export default StarEmpty;

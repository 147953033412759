// extracted by mini-css-extract-plugin
export var home_historyContainer = "homeHistory-module--home_historyContainer--uwykh";
export var home_historyImage1Left = "homeHistory-module--home_historyImage1Left--HStcG";
export var home_historyImage1Right = "homeHistory-module--home_historyImage1Right--YkUoO";
export var home_historyImage2 = "homeHistory-module--home_historyImage2--wEF8e";
export var home_historyImage3 = "homeHistory-module--home_historyImage3--x7AvG";
export var home_historyImage4 = "homeHistory-module--home_historyImage4--WPT8Z";
export var home_historyImageLeft = "homeHistory-module--home_historyImageLeft--ZTZnV";
export var home_historyImageLeftMobile = "homeHistory-module--home_historyImageLeftMobile--yFMWU";
export var home_historyImageRight = "homeHistory-module--home_historyImageRight--zWid7";
export var home_historyImageRightMobile = "homeHistory-module--home_historyImageRightMobile--DAovm";
export var home_historyText = "homeHistory-module--home_historyText--rcpLE";
export var hovered = "homeHistory-module--hovered--p49Qr";